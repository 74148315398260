import React from 'react';
import { Route, Switch } from 'react-router-dom';

// Admin
import {
  CreateAdminUser as AdminCreateAdminUser,
  CreateCategory as AdminCreateCategory,
  CreateCategoryItem as AdminCreateCategoryItem,
  CreateLocation as AdminCreateLocation,
  CreateLocationPrinters as AdminCreateLocationPrinters,
  CreateLocationStaff as AdminCreateLocationStaff,
  CreateLocationUser as AdminCreateLocationUser,
  CreateLocationVoucher as AdminCreateLocationVoucher,
  CreateMenu as AdminCreateMenu,
  CreateOrganisation as AdminCreateOrganisation,
  CreateOrganisationUser as AdminCreateOrganisationUser,
  CreateReport as AdminCreateReport,
  DeviceApps as AdminOrgDeviceApps,
  EditAdminUser as AdminEditAdminUser,
  EditCategory as AdminEditCategory,
  EditCategoryItem as AdminEditCategoryItem,
  EditLocation as AdminEditLocation,
  EditLocationIntegrations as AdminEditLocationIntegrations,
  EditLocationPrinters as AdminEditLocationPrinters,
  EditLocationStaff as AdminEditLocationStaff,
  EditLocationUser as AdminEditLocationUser,
  EditLocationVoucher as AdminEditLocationVoucher,
  EditMenu as AdminEditMenu,
  EditOrganisation as AdminEditOrganisation,
  EditOrganisationUser as AdminEditOrganisationUser,
  Home as AdminHome,
  ListAdminUsers as AdminListAdminUsers,
  ListLocationPrinters as AdminListLocationPrinters,
  ListLocations as AdminLocations,
  ListLocationStaff as AdminListLocationStaff,
  ListLocationUsers as AdminListLocationUsers,
  ListLocationVouchers as AdminListLocationVouchers,
  ListMenus as AdminListMenus,
  ListOrganisations as AdminOrganisations,
  ListOrganisationUsers as AdminListOrganisationUsers,
  ListReports as AdminListReports,
  Referrals as AdminReferrals,
  UploadMenu as AdminUploadMenu,
  ViewAdminUser as AdminViewAdminUser,
  ViewCategory as AdminViewCategory,
  ViewLocationIntegrations as AdminViewLocationIntegrations,
  ViewLocationOrders as AdminViewLocationOrders,
  ViewLocationStaff as AdminViewLocationStaff,
  ViewLocationUser as AdminViewLocationUser,
  ViewMenu as AdminViewMenu,
  ViewOrganisation as AdminViewOrganisation,
  ViewOrganisationUser as AdminViewOrganisationUser,
  ViewStock as AdminViewStock,
} from './views/Admin';

// Organisation
import {
  CreateCategory as OrgCreateCategory,
  CreateCategoryItem as OrgCreateCategoryItem,
  CreateLocation as OrgCreateLocation,
  CreateLocationPrinters as OrgCreateLocationPrinters,
  CreateLocationStaff as OrgCreateLocationStaff,
  CreateLocationUser as OrgCreateLocationUser,
  CreateLocationVoucher as OrgCreateLocationVoucher,
  CreateMenu as OrgCreateMenu,
  CreateUser as OrgCreateUser,
  EditCategory as OrgEditCategory,
  EditCategoryItem as OrgEditCategoryItem,
  EditLocation as OrgEditLocation,
  EditLocationIntegrations as OrgEditLocationIntegrations,
  EditLocationPrinters as OrgEditLocationPrinters,
  EditLocationStaff as OrgEditLocationStaff,
  EditLocationUser as OrgEditLocationUser,
  EditLocationVoucher as OrgEditLocationVoucher,
  EditMenu as OrgEditMenu,
  EditUser as OrgEditUser,
  Home as OrgHome,
  ListLocationPrinters as OrgListLocationPrinters,
  ListLocations as OrgLocations,
  ListLocationStaff as OrgListLocationStaff,
  ListLocationUsers as OrgListLocationUsers,
  ListLocationVouchers as OrgListLocationVouchers,
  ListMenus as OrgListMenus,
  ListReports as OrgListReports,
  ListUsers as OrgListUsers,
  UploadMenu as OrgUploadMenu,
  ViewCategory as OrgViewCategory,
  ViewLocation as OrgViewLocation,
  ViewLocationIntegrations as OrgViewLocationIntegrations,
  ViewLocationOrders as OrgViewLocationOrders,
  ViewLocationStaff as OrgViewLocationStaff,
  ViewLocationUser as OrgViewLocationUser,
  ViewMenu as OrgViewMenu,
  ViewUser as OrgViewUser,
} from './views/Organisation';

// Location
import {
  CreateCategory as LocCreateCategory,
  CreateCategoryItem as LocCreateCategoryItem,
  CreateMenu as LocCreateMenu,
  CreatePrinters as LocCreatePrinters,
  CreateStaff as LocCreateStaff,
  CreateUser as LocCreateUser,
  CreateVoucher as LocCreateVoucher,
  EditCategory as LocEditCategory,
  EditCategoryItem as LocEditCategoryItem,
  EditMenu as LocEditMenu,
  EditPrinters as LocEditPrinters,
  EditStaff as LocEditStaff,
  EditUser as LocEditUser,
  EditVoucher as LocEditVoucher,
  Home as LocHome,
  ListMenus as LocListMenus,
  ListPrinters as LocListPrinters,
  ListReports as LocListReports,
  ListStaff as LocListStaff,
  ListUsers as LocListUsers,
  ListVouchers as LocListVouchers,
  UploadMenu as LocUploadMenu,
  ViewCategory as LocViewCategory,
  ViewLocationOrders as LocViewOrders,
  ViewMenu as LocViewMenu,
  ViewStaff as LocViewStaff,
  ViewUser as LocViewUser,
} from './views/Location';

// Public
import { CreateSelfOnboardOrganisation, InitialSignIn, Referral, ResetPassword, SignIn, Splash, ViewMenu, XeroSuccess, XeroUnsuccessful, ViewRedesign } from './views/Public';

import NotFound from './components/Routing/NotFound';
import NotAuthorised from './components/Routing/NotAuthorised';
import { ProtectedRoleRoute } from './components/Routing';

import { adminRoles, locationRoles, organisationRoles } from './components/AuthProvider';

const Routes = () => {
  return (
    <Switch>
      {/* ---------- public ---------- */}
      <Route exact path="/sign-in" component={SignIn} />
      <Route exact path="/initial-sign-in" component={InitialSignIn} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route exact path="/referral/:referral_name" component={Referral} />
      <Route exact path="/integrations/xero/success" component={XeroSuccess} />
      <Route exact path="/integrations/xero/unsuccessful" component={XeroUnsuccessful} />
      <Route exact path="/self-onboarding/create" component={CreateSelfOnboardOrganisation} />

      <Route exact path="/organisations/:organisation_id/locations/:location_id/ordering/menu" component={ViewMenu} />
      <Route exact path="/organisations/:organisation_id/locations/:location_id/ordering/menu1" component={ViewRedesign} />

      <Route exact path="/" component={Splash} />

      {/* ---------- admin ---------- */}
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/home" component={AdminHome} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/referrals" component={AdminReferrals} />

      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/users" component={AdminListAdminUsers} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/users/create" component={AdminCreateAdminUser} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/users/:admin_user_id" component={AdminViewAdminUser} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/users/:admin_user_id/edit" component={AdminEditAdminUser} />

      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/reports" component={AdminListReports} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/reports/create" component={AdminCreateReport} />

      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/device-apps" component={AdminOrgDeviceApps} />

      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations" component={AdminOrganisations} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/create" component={AdminCreateOrganisation} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id" component={AdminViewOrganisation} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/edit" component={AdminEditOrganisation} />

      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/users" component={AdminListOrganisationUsers} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/users/create" component={AdminCreateOrganisationUser} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/users/:organisation_user_id" component={AdminViewOrganisationUser} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/users/:organisation_user_id/edit" component={AdminEditOrganisationUser} />

      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations" component={AdminLocations} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/create" component={AdminCreateLocation} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id" component={AdminEditLocation} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/edit" component={AdminEditLocation} />

      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/users" component={AdminListLocationUsers} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/users/create" component={AdminCreateLocationUser} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/users/:location_user_id" component={AdminViewLocationUser} />
      <ProtectedRoleRoute
        roles={adminRoles}
        exact
        path="/admin/organisations/:organisation_id/locations/:location_id/users/:location_user_id/edit"
        component={AdminEditLocationUser}
      />

      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/staff" component={AdminListLocationStaff} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/staff/create" component={AdminCreateLocationStaff} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/staff/:staff_user_id" component={AdminViewLocationStaff} />
      <ProtectedRoleRoute
        roles={adminRoles}
        exact
        path="/admin/organisations/:organisation_id/locations/:location_id/staff/:staff_user_id/edit"
        component={AdminEditLocationStaff}
      />

      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/printers" component={AdminListLocationPrinters} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/printers/create" component={AdminCreateLocationPrinters} />
      <ProtectedRoleRoute
        roles={adminRoles}
        exact
        path="/admin/organisations/:organisation_id/locations/:location_id/printers/:printer_id/edit"
        component={AdminEditLocationPrinters}
      />

      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/vouchers" component={AdminListLocationVouchers} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/vouchers/create" component={AdminCreateLocationVoucher} />
      <ProtectedRoleRoute
        roles={adminRoles}
        exact
        path="/admin/organisations/:organisation_id/locations/:location_id/vouchers/:voucher_id/edit"
        component={AdminEditLocationVoucher}
      />

      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/orders" component={AdminViewLocationOrders} />

      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/integrations" component={AdminViewLocationIntegrations} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/stock" component={AdminViewStock} />
      <ProtectedRoleRoute
        roles={adminRoles}
        exact
        path="/admin/organisations/:organisation_id/locations/:location_id/integrations/edit"
        component={AdminEditLocationIntegrations}
      />

      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/menus" component={AdminListMenus} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/menus/create" component={AdminCreateMenu} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/menus/upload" component={AdminUploadMenu} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/menus/:menu_id" component={AdminViewMenu} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/menus/:menu_id/edit" component={AdminEditMenu} />

      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/menus/:menu_id/:category_type/categories/create" component={AdminCreateCategory} />
      <ProtectedRoleRoute
        roles={adminRoles}
        exact
        path="/admin/organisations/:organisation_id/menus/:menu_id/:category_type/categories/:category_id"
        component={AdminViewCategory}
      />
      <ProtectedRoleRoute
        roles={adminRoles}
        exact
        path="/admin/organisations/:organisation_id/menus/:menu_id/:category_type/categories/:category_id/edit"
        component={AdminEditCategory}
      />

      <ProtectedRoleRoute
        roles={adminRoles}
        exact
        path="/admin/organisations/:organisation_id/menus/:menu_id/:category_type/categories/:category_id/items/create"
        component={AdminCreateCategoryItem}
      />
      <ProtectedRoleRoute
        roles={adminRoles}
        exact
        path="/admin/organisations/:organisation_id/menus/:menu_id/:category_type/categories/:category_id/items/:item_id/edit"
        component={AdminEditCategoryItem}
      />

      {/* ---------- organisation ---------- */}
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/home" component={OrgHome} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/reports" component={OrgListReports} />

      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/users" component={OrgListUsers} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/users/create" component={OrgCreateUser} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/users/:organisation_user_id" component={OrgViewUser} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/users/:organisation_user_id/edit" component={OrgEditUser} />

      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations" component={OrgLocations} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/create" component={OrgCreateLocation} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id" component={OrgEditLocation} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/edit" component={OrgEditLocation} />

      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/users" component={OrgListLocationUsers} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/users/create" component={OrgCreateLocationUser} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/users/:location_user_id" component={OrgViewLocationUser} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/users/:location_user_id/edit" component={OrgEditLocationUser} />

      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/staff" component={OrgListLocationStaff} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/staff/create" component={OrgCreateLocationStaff} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/staff/:staff_user_id" component={OrgViewLocationStaff} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/staff/:staff_user_id/edit" component={OrgEditLocationStaff} />

      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/printers" component={OrgListLocationPrinters} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/printers/create" component={OrgCreateLocationPrinters} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/printers/:printer_id/edit" component={OrgEditLocationPrinters} />

      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/vouchers" component={OrgListLocationVouchers} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/vouchers/create" component={OrgCreateLocationVoucher} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/vouchers/:voucher_id/edit" component={OrgEditLocationVoucher} />

      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/integrations" component={OrgViewLocationIntegrations} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/integrations/edit" component={OrgEditLocationIntegrations} />

      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/orders" component={OrgViewLocationOrders} />

      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/menus" component={OrgListMenus} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/menus/create" component={OrgCreateMenu} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/menus/upload" component={OrgUploadMenu} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/menus/:menu_id" component={OrgViewMenu} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/menus/:menu_id/edit" component={OrgEditMenu} />

      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/menus/:menu_id/:category_type/categories/create" component={OrgCreateCategory} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/menus/:menu_id/:category_type/categories/:category_id" component={OrgViewCategory} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/menus/:menu_id/:category_type/categories/:category_id/edit" component={OrgEditCategory} />

      <ProtectedRoleRoute
        roles={[...organisationRoles, ...adminRoles]}
        exact
        path="/org/menus/:menu_id/:category_type/categories/:category_id/items/create"
        component={OrgCreateCategoryItem}
      />
      <ProtectedRoleRoute
        roles={[...organisationRoles, ...adminRoles]}
        exact
        path="/org/menus/:menu_id/:category_type/categories/:category_id/items/:item_id/edit"
        component={OrgEditCategoryItem}
      />

      {/* ---------- location ---------- */}
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/home" component={LocHome} />
      <ProtectedRoleRoute roles={[...locationRoles, ...adminRoles]} exact path="/loc/reports" component={LocListReports} />

      {/* <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/bookings" component={LocListBookings} /> */}

      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/users" component={LocListUsers} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/users/create" component={LocCreateUser} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/users/:location_user_id" component={LocViewUser} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/users/:location_user_id/edit" component={LocEditUser} />

      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/staff" component={LocListStaff} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/staff/create" component={LocCreateStaff} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/staff/:staff_user_id" component={LocViewStaff} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/staff/:staff_user_id/edit" component={LocEditStaff} />

      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/printers" component={LocListPrinters} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/printers/create" component={LocCreatePrinters} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/printers/:printer_id/edit" component={LocEditPrinters} />

      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/vouchers" component={LocListVouchers} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/vouchers/create" component={LocCreateVoucher} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/vouchers/:voucher_id/edit" component={LocEditVoucher} />

      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/orders" component={LocViewOrders} />

      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/menus" component={LocListMenus} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/menus/create" component={LocCreateMenu} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/menus/upload" component={LocUploadMenu} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/menus/:menu_id" component={LocViewMenu} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/menus/:menu_id/edit" component={LocEditMenu} />

      <ProtectedRoleRoute
        roles={[...locationRoles, ...organisationRoles, ...adminRoles]}
        exact
        path="/loc/menus/:menu_id/:category_type/categories/create"
        component={LocCreateCategory}
      />
      <ProtectedRoleRoute
        roles={[...locationRoles, ...organisationRoles, ...adminRoles]}
        exact
        path="/loc/menus/:menu_id/:category_type/categories/:category_id"
        component={LocViewCategory}
      />
      <ProtectedRoleRoute
        roles={[...locationRoles, ...organisationRoles, ...adminRoles]}
        exact
        path="/loc/menus/:menu_id/:category_type/categories/:category_id/edit"
        component={LocEditCategory}
      />

      <ProtectedRoleRoute
        roles={[...locationRoles, ...organisationRoles, ...adminRoles]}
        exact
        path="/loc/menus/:menu_id/:category_type/categories/:category_id/items/create"
        component={LocCreateCategoryItem}
      />
      <ProtectedRoleRoute
        roles={[...locationRoles, ...organisationRoles, ...adminRoles]}
        exact
        path="/loc/menus/:menu_id/:category_type/categories/:category_id/items/:item_id/edit"
        component={LocEditCategoryItem}
      />

      <Route exact path="/not-authorised" component={NotAuthorised} />
      <Route exact component={NotFound} />
    </Switch>
  );
};

export default Routes;
