import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import CreateLocation from '../../../../components/Global/Location/Create';

import { createLocationInitQuery, Organisation } from './queries';

import styles from './styles';
import { Breadcrum } from '../../../../layouts/Dashboard/components/Topbar';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const OrgCreateLocation = ({ classes, history }: Props): React.ReactElement => {
  const [organisation, setOrganisation] = useState<Organisation>({
    tax_rate: 0,
  });

  const { data: createLocationInitData } = useQuery(createLocationInitQuery(), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && createLocationInitData) {
      // setOrganisation(createLocationInitData.organisations_by_pk);
      setOrganisation(createLocationInitData.organisations[0]);
    }
    return () => {
      mounted = false;
    };
  }, [createLocationInitData]);

  const handleFinish = (id: string) => {
    history.push(`/org/locations/${id}`);
  };

  const [breadcrumbsUpdated, setBreadcrumbsUpdated] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrum[]>([{ label: 'Locations', link: '/org/locations' }]);
  useEffect(() => {
    if (organisation) {
      setBreadcrumbs([{ label: 'Locations', link: '/org/locations' }]);
      setBreadcrumbsUpdated(true);
    }
  }, [organisation]);
  return (
    <>
      {organisation?.tax_rate && (
        <CreateLocation
          breadcrumbsUpdated={breadcrumbsUpdated}
          setBreadcrumbs={setBreadcrumbs}
          breadcrumbs={breadcrumbs}
          tax_rate={organisation.tax_rate}
          handleFinish={handleFinish}
        />
      )}
    </>
  );
};

export default withRouter(withStyles(styles)(OrgCreateLocation));
