import { capitaliseFirstOnly, numbersOnly } from '../../../../utils/stringUtils';
import { ValidationType } from '../../../../react-app-env';
import { validateEmailAddress } from '../../../../utils/validators';
import { OrderType } from '../../Menus/Common';

export interface Location {
  id?: string;
  name: string;
  email: string;
  phone: string;
  website: string;
  tax_number: string;
  number_of_tables: number;
  location_type: string;
  address_line_1: string;
  address_line_2: string;
  address_city: string;
  address_country: string;
  address_postcode: string;
  currency: string;
  locale: string;
  tax_rate: number;
  service_charge: ServiceCharge | null;
  can_manage_menus: boolean;
  has_kitchen_printer: boolean;
  has_bar_printer: boolean;
  has_staff: boolean;
  has_station_printers: boolean;
  idle_timeout: number;
  location_menu_id: string | undefined;
  ordering_system_primary_color: string | undefined;
  ordering_system_secondary_color: string | undefined;
  header_image_data?: string;
  header_image?: string;
  logo_image_data?: string;
  logo_image?: string;
  header_image_added: boolean;
  logo_image_added: boolean;
  ordering_system_open_hours: OpeningHours[] | null;
  ordering_system_preorder_hours: OpeningHours[] | null;
  preorder_days_limit: number | null;
  preorder_pacing_limit: number | null;
  order_interval: number;
  inventory_restock_type: string;
  voucher_codes_aggregate: {
    aggregate: {
      count: number;
    };
  };
  integrations: {
    vouchers: boolean;
  };
}

type Day = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday';

export interface OpeningHours {
  day: Day;
  open: string;
  close: string;
}

export const initialOpeningHours: OpeningHours[] = [
  { day: 'Monday', open: '', close: '' },
  { day: 'Tuesday', open: '', close: '' },
  { day: 'Wednesday', open: '', close: '' },
  { day: 'Thursday', open: '', close: '' },
  { day: 'Friday', open: '', close: '' },
  { day: 'Saturday', open: '', close: '' },
  { day: 'Sunday', open: '', close: '' },
];

export const initialPreorderHours: OpeningHours[] = [
  { day: 'Monday', open: '', close: '' },
  { day: 'Tuesday', open: '', close: '' },
  { day: 'Wednesday', open: '', close: '' },
  { day: 'Thursday', open: '', close: '' },
  { day: 'Friday', open: '', close: '' },
  { day: 'Saturday', open: '', close: '' },
  { day: 'Sunday', open: '', close: '' },
];

export interface ServiceCharge {
  order_types: OrderType[];
  percentage: number;
  min_covers: number;
}

export interface LocationType {
  location_type: string;
  description: string;
}

export interface InventoryRestockType {
  inventory_restock_type: string;
  description: string;
}

export interface LocationMenu {
  id: string;
  name: string;
  description: string;
  is_primary: boolean;
}

export interface ValidationErrors {
  name: ValidationType;
  email: ValidationType;
  phone: ValidationType;
  email_invalid: ValidationType;
  tax_number: ValidationType;
  number_of_tables: ValidationType;
  location_type: ValidationType;
  address_line_1: ValidationType;
  address_city: ValidationType;
  address_country: ValidationType;
  address_postcode: ValidationType;
  currency: ValidationType;
  locale: ValidationType;
  stock_type: ValidationType;
  tax_rate: ValidationType;
  service_charge_order_types: ValidationType;
  service_charge_percentage: ValidationType;
  service_charge_percentage_too_small: ValidationType;
  service_charge_percentage_too_large: ValidationType;
}

export const validationErrors: ValidationErrors = {
  name: 'Please provide a name',
  email: 'Please provide an email address',
  email_invalid: 'Please provide a valid email address',
  phone: 'Please provide a phone number',
  tax_number: 'Please provide a tax number (VAT)',
  number_of_tables: 'Please provide how many tables',
  location_type: 'Please provide a location type',
  address_line_1: 'Please provide a first line of address',
  address_city: 'Please provide a city',
  address_country: 'Please provide a country',
  address_postcode: 'Please provide a postcode',
  currency: 'Please provide a currency',
  locale: 'Please provide a locale',
  stock_type: 'Please provide a stock type',
  tax_rate: 'Please provide a tax rate',
  service_charge_order_types: 'Please provide an order type',
  service_charge_percentage: 'Please provide a percentage',
  service_charge_percentage_too_small: 'Please provide a percentage',
  service_charge_percentage_too_large: 'Please provide a percentage less than 100',
};

export interface Validation {
  name: (value: string) => ValidationType;
  email: (value: string) => ValidationType;
  phone: (value: string) => ValidationType;
  tax_number: (value: string) => ValidationType;
  number_of_tables: (value: number) => ValidationType;
  location_type: (value: string) => ValidationType;
  address_line_1: (value: string) => ValidationType;
  address_city: (value: string) => ValidationType;
  address_country: (value: string) => ValidationType;
  address_postcode: (value: string) => ValidationType;
  currency: (value: string) => ValidationType;
  locale: (value: string) => ValidationType;
  stock_type: (value: string) => ValidationType;
  tax_rate: (value: string) => ValidationType;
  service_charge_order_types: (value: OrderType[]) => ValidationType;
  service_charge_percentage: (value: number) => ValidationType;
}

export const validation: Validation = {
  name: (value) => (!value ? validationErrors.name : false),
  email: (value) => {
    if (!value) return validationErrors.email;
    if (!validateEmailAddress(value)) return validationErrors.email_invalid;
    return false;
  },
  phone: (value) => (!value ? validationErrors.phone : false),
  tax_number: (value) => (!value ? validationErrors.tax_number : false),
  location_type: (value) => (!value ? validationErrors.location_type : false),
  number_of_tables: (value: number) => (value === 0 ? validationErrors.number_of_tables : false),
  address_line_1: (value) => (!value ? validationErrors.address_line_1 : false),
  address_city: (value) => (!value ? validationErrors.address_city : false),
  address_country: (value) => (!value ? validationErrors.address_country : false),
  address_postcode: (value) => (!value ? validationErrors.address_postcode : false),
  currency: (value) => (!value ? validationErrors.currency : false),
  locale: (value) => (!value ? validationErrors.locale : false),
  stock_type: (value) => (!value ? validationErrors.stock_type : false),
  tax_rate: (value) => (!value ? validationErrors.tax_rate : false),
  service_charge_order_types: (value: OrderType[]) => (value.length === 0 ? validationErrors.service_charge_order_types : false),
  service_charge_percentage: (value: number) => {
    if (value <= 0) return validationErrors.service_charge_percentage_too_small;
    if (value > 100) return validationErrors.service_charge_percentage_too_large;
    return false;
  },
};

export enum LocationReducerAction {
  NAME,
  EMAIL,
  PHONE,
  WEBSITE,
  TAX_NUMBER,
  NUMBER_OF_TABLES,
  LOCATION_TYPE,
  ADDRESS_LINE_1,
  ADDRESS_LINE_2,
  ADDRESS_CITY,
  ADDRESS_COUNTRY,
  ADDRESS_POSTCODE,
  CURRENCY,
  LOCALE,
  TAX_RATE,
  SERVICE_CHARGE_ACTIVE,
  SERVICE_CHARGE_ORDER_TYPES,
  SERVICE_CHARGE_PERCENT,
  SERVICE_CHARGE_COVERS,
  CAN_MANAGE_MENUS,
  HAS_KITCHEN_PRINTER,
  HAS_BAR_PRINTER,
  HAS_STAFF,
  HAS_STATION_PRINTERS,
  IDLE_TIMEOUT,
  SET_LOCATION_MENU,
  SET_ORDERING_SYSTEM_PRIMARY_COLOR,
  SET_ORDERING_SYSTEM_SECONDARY_COLOR,
  SET_HEADER_IMAGE_DATA,
  SET_HEADER_IMAGE,
  SET_LOGO_IMAGE_DATA,
  SET_LOGO_IMAGE,
  CLEAR_HEADER_IMAGE,
  CLEAR_LOGO_IMAGE,
  SET_ORDERING_SYSTEM_OPEN_HOURS,
  SET_ORDERING_SYSTEM_PREORDER_HOURS,
  SET_PREORDER_DAYS_LIMIT,
  SET_PREORDER_PACING_LIMIT,
  SET_ORDER_INTERVAL,
  SET_INVENTORY_RESTOCK_TYPE,
  INIT,
}

export const locationReducer = (state: Location, action: { type: LocationReducerAction; value: any }): Location => {
  switch (action.type) {
    case LocationReducerAction.NAME:
      return { ...state, name: capitaliseFirstOnly(action.value) };
    case LocationReducerAction.EMAIL:
      return { ...state, email: action.value.toLowerCase() };
    case LocationReducerAction.PHONE:
      return { ...state, phone: action.value };
    case LocationReducerAction.WEBSITE:
      return { ...state, website: action.value };
    case LocationReducerAction.TAX_NUMBER:
      return { ...state, tax_number: action.value };
    case LocationReducerAction.NUMBER_OF_TABLES:
      return { ...state, number_of_tables: +numbersOnly(action.value) };
    case LocationReducerAction.LOCATION_TYPE:
      return { ...state, location_type: action.value };
    case LocationReducerAction.ADDRESS_LINE_1:
      return { ...state, address_line_1: action.value };
    case LocationReducerAction.ADDRESS_LINE_2:
      return { ...state, address_line_2: action.value };
    case LocationReducerAction.ADDRESS_CITY:
      return { ...state, address_city: action.value };
    case LocationReducerAction.ADDRESS_COUNTRY:
      return { ...state, address_country: action.value };
    case LocationReducerAction.ADDRESS_POSTCODE:
      return { ...state, address_postcode: action.value };
    case LocationReducerAction.TAX_RATE:
      return { ...state, tax_rate: +numbersOnly(action.value) };
    case LocationReducerAction.SERVICE_CHARGE_ACTIVE:
      return {
        ...state,
        service_charge: action.value
          ? {
              order_types: [],
              percentage: 0,
              min_covers: 0,
            }
          : null,
      };
    case LocationReducerAction.SERVICE_CHARGE_ORDER_TYPES:
      let oTypes = state.service_charge!.order_types;
      if (action.value.active) {
        oTypes.push(action.value.orderType);
      } else {
        oTypes = oTypes.filter((m) => m !== action.value.orderType);
      }
      return {
        ...state,
        service_charge: {
          order_types: oTypes,
          percentage: state.service_charge!.percentage,
          min_covers: state.service_charge!.min_covers,
        },
      };
    case LocationReducerAction.SERVICE_CHARGE_PERCENT:
      return {
        ...state,
        service_charge: {
          order_types: state.service_charge!.order_types,
          percentage: +action.value,
          min_covers: state.service_charge!.min_covers,
        },
      };
    case LocationReducerAction.SERVICE_CHARGE_COVERS:
      return {
        ...state,
        service_charge: {
          order_types: state.service_charge!.order_types,
          percentage: state.service_charge!.percentage,
          min_covers: +numbersOnly(action.value),
        },
      };
    case LocationReducerAction.CURRENCY:
      return { ...state, currency: action.value };
    case LocationReducerAction.LOCALE:
      return { ...state, locale: action.value };
    case LocationReducerAction.CAN_MANAGE_MENUS:
      return { ...state, can_manage_menus: action.value as boolean };
    case LocationReducerAction.HAS_KITCHEN_PRINTER:
      return { ...state, has_kitchen_printer: action.value as boolean };
    case LocationReducerAction.HAS_BAR_PRINTER:
      return { ...state, has_bar_printer: action.value as boolean };
    case LocationReducerAction.HAS_STAFF:
      return { ...state, has_staff: action.value as boolean };
    case LocationReducerAction.HAS_STATION_PRINTERS:
      return { ...state, has_station_printers: action.value as boolean };
    case LocationReducerAction.IDLE_TIMEOUT:
      return { ...state, idle_timeout: action.value as number };
    case LocationReducerAction.SET_LOCATION_MENU:
      return { ...state, location_menu_id: action.value };
    case LocationReducerAction.SET_ORDERING_SYSTEM_PRIMARY_COLOR:
      return { ...state, ordering_system_primary_color: action.value };
    case LocationReducerAction.SET_ORDERING_SYSTEM_SECONDARY_COLOR:
      return { ...state, ordering_system_secondary_color: action.value };
    case LocationReducerAction.SET_HEADER_IMAGE_DATA:
      return { ...state, header_image_data: action.value };
    case LocationReducerAction.SET_HEADER_IMAGE:
      return { ...state, header_image: action.value };
    case LocationReducerAction.SET_LOGO_IMAGE_DATA:
      return { ...state, logo_image_data: action.value };
    case LocationReducerAction.SET_LOGO_IMAGE:
      return { ...state, logo_image: action.value };
    case LocationReducerAction.CLEAR_HEADER_IMAGE:
      return { ...state, header_image: undefined, header_image_data: undefined, header_image_added: false };
    case LocationReducerAction.CLEAR_LOGO_IMAGE:
      return { ...state, logo_image: undefined, logo_image_data: undefined, logo_image_added: false };
    case LocationReducerAction.SET_ORDERING_SYSTEM_OPEN_HOURS:
      return {
        ...state,
        ordering_system_open_hours: state.ordering_system_open_hours!.map((entry) =>
          entry.day === action.value.day ? { ...entry, [action.value.timeType]: action.value.value } : entry
        ),
      };
    case LocationReducerAction.SET_ORDERING_SYSTEM_PREORDER_HOURS:
      return {
        ...state,
        ordering_system_preorder_hours: state.ordering_system_preorder_hours!.map((entry) =>
          entry.day === action.value.day ? { ...entry, [action.value.timeType]: action.value.value } : entry
        ),
      };
    case LocationReducerAction.SET_INVENTORY_RESTOCK_TYPE:
      return { ...state, inventory_restock_type: action.value };
    case LocationReducerAction.SET_PREORDER_DAYS_LIMIT:
      return { ...state, preorder_days_limit: action.value };
    case LocationReducerAction.SET_PREORDER_PACING_LIMIT:
      return { ...state, preorder_pacing_limit: action.value };
    case LocationReducerAction.SET_ORDER_INTERVAL:
      return { ...state, order_interval: action.value };

    case LocationReducerAction.INIT:
      const {
        name,
        email,
        phone,
        website,
        location_type,
        number_of_tables,
        address_line_1,
        address_line_2,
        address_city,
        address_country,
        address_postcode,
        tax_number,
        service_charge,
        currency,
        locale,
        can_manage_menus,
        has_kitchen_printer,
        has_bar_printer,
        has_staff,
        has_station_printers,
        idle_timeout,
        location_menu_id,
        ordering_system_primary_color,
        ordering_system_secondary_color,
        header_image_added,
        logo_image_added,
        ordering_system_open_hours,
        ordering_system_preorder_hours,
        preorder_days_limit,
        preorder_pacing_limit,
        order_interval,
        inventory_restock_type,
        voucher_codes_aggregate,
        integrations,
      } = action.value;
      const obj = {
        name,
        email,
        phone,
        website,
        number_of_tables,
        location_type,
        address_line_1,
        address_line_2,
        address_city,
        address_country,
        address_postcode,
        tax_number,
        service_charge,
        currency,
        locale,
        can_manage_menus,
        has_kitchen_printer,
        has_bar_printer,
        has_staff,
        has_station_printers,
        idle_timeout,
        location_menu_id,
        ordering_system_primary_color,
        ordering_system_secondary_color,
        header_image_added,
        logo_image_added,
        ordering_system_open_hours: ordering_system_open_hours ? ordering_system_open_hours : initialOpeningHours,
        ordering_system_preorder_hours: ordering_system_preorder_hours ? ordering_system_preorder_hours : initialPreorderHours,
        preorder_days_limit,
        preorder_pacing_limit,
        order_interval,
        inventory_restock_type,
        voucher_codes_aggregate,
        integrations,
      };
      return { ...(obj as Location) };
    default:
      throw new Error();
  }
};

export interface LocationIntegration {
  id: string;
  payment: PaymentIntegration;
  deliverect: DeliverectIntegration;
  xero: XeroIntegration;
  vouchers: boolean;
}

export interface PaymentIntegration {
  active: boolean;
  provider: PaymentProvider | null;
  isOnboarded?: boolean | null;
  merchantId?: string | null;
  links?: Array<any> | null;
  accountId: string;
  enableTipping?: boolean;
  password: string;
  username: string;
}

export interface DeliverectIntegration {
  active: boolean;
  accountId: string;
  locationId: string;
  autoAccept: boolean;
}

export interface XeroIntegration {
  active: boolean;
  code: string;
}

export interface ValidationIntegrationErrors {
  deliverectAccountId: ValidationType;
  deliverectLocationId: ValidationType;
  paymentAccountId: ValidationType;
  paymentProvider: ValidationType;
  paymentUsername: ValidationType;
  paymentPassword: ValidationType;
  xeroCode: ValidationType;
}

export const validationIntegrationErrors: ValidationIntegrationErrors = {
  deliverectAccountId: 'Please provide an account id',
  deliverectLocationId: 'Please provide an location id',
  paymentAccountId: 'Please provide an account id',
  paymentProvider: 'Please provide a payment provider',
  paymentUsername: 'Please provide a payment username',
  paymentPassword: 'Please provide a payment password',
  xeroCode: 'You must login to xero and authorise AccentPOS',
};

export interface ValidationIntegration {
  deliverectAccountId: (value: string) => ValidationType;
  deliverectLocationId: (value: string) => ValidationType;
  paymentProvider: (value: PaymentProvider | null) => ValidationType;
  paymentAccountId: (value: string) => ValidationType;
  paymentUsername: (value: string) => ValidationType;
  paymentPassword: (value: string) => ValidationType;
  xeroCode: (value: string) => ValidationType;
}

export const validationIntegration: ValidationIntegration = {
  deliverectAccountId: (value) => (!value ? validationIntegrationErrors.deliverectAccountId : false),
  deliverectLocationId: (value) => (!value ? validationIntegrationErrors.deliverectLocationId : false),
  paymentProvider: (value) => (!value ? validationIntegrationErrors.paymentProvider : false),
  paymentAccountId: (value) => (!value ? validationIntegrationErrors.paymentAccountId : false),
  paymentUsername: (value) => (!value ? validationIntegrationErrors.paymentUsername : false),
  paymentPassword: (value) => (!value ? validationIntegrationErrors.paymentPassword : false),
  xeroCode: (value) => (!value ? validationIntegrationErrors.xeroCode : false),
};

export enum LocationIntegrationReducerAction {
  DELIVERECT_ACTIVE,
  DELIVERECT_ACCOUNT_ID,
  DELIVERECT_LOCATION_ID,
  DELIVERECT_AUTO_ACCEPT,
  PAYMENT_ACTIVE,
  PAYMENT_ACCOUNT_ID,
  PAYMENT_TIPPING,
  PAYMENT_PROVIDER,
  PAYMENT_USERNAME,
  PAYMENT_PASSWORD,
  PAYMENT_LINKS,
  PAYMENT_ISONBOARDED,
  PAYMENT_MERCHANTID,
  XERO_ACTIVE,
  XERO_ACCOUNT_ID,
  VOUCHERS,
  INIT,
}

export enum PaymentProvider {
  DOJO = 'DOJO',
  ZETTLE = 'ZETTLE',
  PAYPAL = 'PAYPAL',
}

export const locationIntegrationReducer = (
  state: LocationIntegration,
  action: {
    type: LocationIntegrationReducerAction;
    value: any;
  }
): LocationIntegration => {
  switch (action.type) {
    case LocationIntegrationReducerAction.DELIVERECT_ACTIVE:
      return {
        ...state,
        deliverect: {
          ...state.deliverect,
          active: action.value as boolean,
        },
      };
    case LocationIntegrationReducerAction.DELIVERECT_ACCOUNT_ID:
      return {
        ...state,
        deliverect: {
          ...state.deliverect,
          accountId: action.value,
        },
      };
    case LocationIntegrationReducerAction.DELIVERECT_LOCATION_ID:
      return {
        ...state,
        deliverect: {
          ...state.deliverect,
          locationId: action.value,
        },
      };
    case LocationIntegrationReducerAction.DELIVERECT_AUTO_ACCEPT:
      return {
        ...state,
        deliverect: {
          ...state.deliverect,
          autoAccept: action.value as boolean,
        },
      };
    case LocationIntegrationReducerAction.PAYMENT_ACTIVE:
      return {
        ...state,
        payment: {
          ...state.payment,
          active: action.value as boolean,
        },
      };
    case LocationIntegrationReducerAction.PAYMENT_ACCOUNT_ID:
      return {
        ...state,
        payment: {
          ...state.payment,
          accountId: action.value,
        },
      };
    case LocationIntegrationReducerAction.PAYMENT_TIPPING:
      return {
        ...state,
        payment: {
          ...state.payment,
          enableTipping: action.value as boolean,
        },
      };
    case LocationIntegrationReducerAction.PAYMENT_PROVIDER:
      return {
        ...state,
        payment: {
          ...state.payment,
          provider: action.value as PaymentProvider,
        },
      };
    case LocationIntegrationReducerAction.PAYMENT_USERNAME:
      return {
        ...state,
        payment: {
          ...state.payment,
          username: action.value,
        },
      };
    case LocationIntegrationReducerAction.PAYMENT_PASSWORD:
      return {
        ...state,
        payment: {
          ...state.payment,
          password: action.value,
        },
      };
    case LocationIntegrationReducerAction.PAYMENT_LINKS:
      return {
        ...state,
        payment: {
          ...state.payment,
          links: action.value,
        },
      };
    case LocationIntegrationReducerAction.PAYMENT_ISONBOARDED:
      return {
        ...state,
        payment: {
          ...state.payment,
          isOnboarded: action.value,
        },
      };
    case LocationIntegrationReducerAction.PAYMENT_MERCHANTID:
      return {
        ...state,
        payment: {
          ...state.payment,
          merchantId: action.value,
        },
      };
    case LocationIntegrationReducerAction.XERO_ACTIVE:
      return {
        ...state,
        xero: {
          ...state.xero,
          active: action.value as boolean,
        },
      };
    case LocationIntegrationReducerAction.XERO_ACCOUNT_ID:
      return {
        ...state,
        xero: {
          ...state.xero,
          code: action.value,
        },
      };
    case LocationIntegrationReducerAction.VOUCHERS:
      return { ...state, vouchers: action.value as boolean };
    case LocationIntegrationReducerAction.INIT:
      const { id, deliverect, payment, xero, vouchers } = action.value || { id: '', vouchers: false };
      const obj = {
        id,
        payment: {
          active: !!payment,
          provider: payment ? (payment.provider as PaymentProvider) : null,
          ...(payment.provider !== PaymentProvider.PAYPAL
            ? {
                accountId: payment ? payment.connection.accountId : '',
                enableTipping: payment ? payment.connection.enableTipping : null,
                username: payment ? payment.connection.username : '',
                password: payment ? payment.connection.password : '',
              }
            : {
                isOnboarded: payment.isOnboarded,
                links: payment.links,
                merchantId: payment.merchantId,
              }),
        },
        deliverect: {
          accountId: deliverect ? deliverect.accountId : '',
          locationId: deliverect ? deliverect.locationId : '',
          active: deliverect ? true : false,
          autoAccept: deliverect ? deliverect.autoAccept : false,
        },
        xero: {
          code: xero ? xero.code : '',
          active: xero ? true : false,
        },
        vouchers,
      };
      return { ...(obj as LocationIntegration) };
    default:
      throw new Error();
  }
};
