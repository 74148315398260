// Function to get 3D Secure settings
import { webhookClient } from '../../../../../../../../utils/webhookClient';
import { useMutation, useQuery } from 'react-query';

interface AdvancedSettings {
  scenario: string;
  action: 'ACCEPT' | 'DECLINE';
}

export const getThreeDSecureSettings = async (locationId: any) => {
  const response = await webhookClient.getAnon(`paypal/3d-secure-settings/${locationId}`);
  return response;
};

// Function to update 3D Secure settings
export const updateThreeDSecureSettings = async (
  locationId: string,
  settings: {
    is3DEnabled: boolean;
    preference: string;
    advancedSettings: AdvancedSettings[];
  }
): Promise<any> => {
  const response = await webhookClient.postAnon(`paypal/3d-secure-settings/${locationId}`, JSON.stringify(settings));
  return response;
};

export const useGetThreeDSecureSettings = (locationId: string) => {
  return useQuery(['three-d-secure-settings', locationId], () => getThreeDSecureSettings(locationId));
};

export const useUpdateThreeDSecureSettings = (locationId: string) => {
  return useMutation((settings: { is3DEnabled: boolean; preference: string; advancedSettings: AdvancedSettings[] }) => updateThreeDSecureSettings(locationId, settings), {
    mutationKey: 'update-three-d-secure-settings',
  });
};
