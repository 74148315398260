import React, { useCallback, useMemo, useState } from 'react';
import { PayPalButtons, PayPalCardFieldsForm, PayPalCardFieldsProvider, PayPalMarks, usePayPalCardFields } from '@paypal/react-paypal-js';
import { CategoryType } from '../../index';
import GooglePayButton from '../GooglePayButton';
import ApplePayButton from '../ApplePayButtonNew';
import ThankYouModal from '../ThankYouModal';

interface Modifier {
  id: string;
  name: string;
  price: number;
  tax: number;
}

interface CartItem {
  id: string;
  name: string;
  quantity: number;
  unit_amount: {
    currency_code: string;
    value: string;
  };
  modifiers: Modifier[];
  price: number;
  tax: number;
}

interface AdvancedSettings {
  scenario: string;
  action: 'ACCEPT' | 'DECLINE';
}

interface CheckoutProps {
  threeDSecureSettings: {
    is3DEnabled: boolean;
    preference: string;
    advancedSettings: AdvancedSettings[];
  };
  paymentSettings: { applePay: boolean; googlePay: boolean; cards: boolean; paypal: boolean };
  cart: Record<string, number>;
  menuData: any;
  createOrder: (request: string) => Promise<any>;
  captureOrder: (request: any) => Promise<any>;
  createServedOrderAfterPaymentAsync: (order: any) => Promise<any>;
  createTakeAwayOrderAfterPaymentAsync: (order: any) => Promise<any>;
  createTakeAwayOrderPaymentAsync: (order: any) => Promise<any>;
  createServedOrderPaymentAsync: (order: any) => Promise<any>;
  location_id: string;
  organisation_id: string;
  orderType: string;
  timeOption: string;
  tableNumber: number | null;
  customerName: string;
  customerPhone: string;
  deliveryAddress: string;
  deliveryPostcode: string;
  scheduledDay: string;
  scheduledTime: string;
  onClose: () => void;
  merchantId: string | null;
}

const INSTRUMENT_DECLINED = 'INSTRUMENT_DECLINED';

const PayPalCheckout: React.FC<CheckoutProps> = ({
  location_id,
  organisation_id,
  orderType,
  timeOption,
  tableNumber = 1,
  scheduledDay,
  scheduledTime,
  cart,
  menuData,
  createOrder,
  captureOrder,
  createServedOrderAfterPaymentAsync,
  createTakeAwayOrderAfterPaymentAsync,
  createTakeAwayOrderPaymentAsync,
  createServedOrderPaymentAsync,
  onClose,
  threeDSecureSettings,
  paymentSettings,
  merchantId,
  customerName,
  customerPhone,
  deliveryAddress,
  deliveryPostcode,
}) => {
  const { is3DEnabled, preference: threeDSecurePreference, advancedSettings } = threeDSecureSettings;
  const { applePay, googlePay, cards, paypal } = paymentSettings;
  const [fundingSource, setFundingSource] = useState<string | null>(null);

  const [isPaying, setIsPaying] = useState(false);
  const [message, setMessage] = useState('');

  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const [orderDetails, setOrderDetails] = useState<any>(null);

  const combineDayAndTime = (day: string, time: string) => `${day}T${time}:00Z`;

  const calculateItemTotalPrice = (item: any): number => {
    const modifiersPrice = item.modifiers?.reduce((sum: number, mod: Modifier) => sum + mod.price, 0) || 0;
    return item.price + modifiersPrice;
  };

  const calculateItemTotalTax = (item: any): number => {
    const modifiersTax = item.modifiers?.reduce((sum: number, mod: Modifier) => sum + mod.tax, 0) || 0;
    return item.tax + modifiersTax;
  };

  const parseModifiersKey = (modifiersKey: string): string[] => (modifiersKey ? modifiersKey.split(',') : []);

  // Build items for orders
  const buildItems = useCallback(
    (categoryType: CategoryType) => {
      return Object.keys(cart).flatMap((cartKey) => {
        const [productId, modifiersKey] = cartKey.split(':');
        const modifiersArray = parseModifiersKey(modifiersKey);
        const product = menuData?.categories
          .flatMap((category: any) =>
            category.category_type === categoryType
              ? category[`${categoryType.toLowerCase()}_assignments`].map((item: any) => ({
                  ...item[categoryType.toLowerCase()],
                  order_index: category.order_index,
                  category_id: category.id,
                  category_name: category.name,
                }))
              : []
          )
          .find((item: any) => item.id === productId);

        if (!product) return [];

        const modifiers = product.modifier_assignments
          .filter((mod: any) => modifiersArray.includes(mod.modifier.name))
          .map((mod: any) => ({
            id: mod.modifier.id,
            name: mod.modifier.name,
            originalPrice: mod.modifier.price,
            originalTax: mod.modifier.tax,
            price: mod.modifier.price,
            tax: mod.modifier.tax,
          }));

        const allergies = product.allergy_assignments.map((allergy: any) => ({
          id: allergy.id,
          name: allergy.allergy.name,
          type: allergy.allergy.allergy_type,
          description: allergy.allergy.description,
        }));

        const price = calculateItemTotalPrice({ price: product.price, modifiers });
        const tax = calculateItemTotalTax({ tax: product.tax, modifiers });

        return Array(cart[cartKey])
          .fill(null)
          .map(() => ({
            item: {
              id: product.id,
              tax: product.tax,
              menu: { id: menuData?.location_info.location_menu_id, name: menuData?.name },
              name: product.name,
              printed: !product.should_print,
              note: null,
              price: product.price,
              category: {
                id: product.category_id,
                name: product.category_name,
                orderIndex: product.order_index,
              },
              prepTime: product.prep_time,
              bypassPrep: product.bypass_prep,
              description: product.description,
              autoServeWhenReady: product.auto_serve_when_ready,
              score: product.score,
              modifiers,
              allergies,
            },
            location_id,
            organisation_id,
            price,
            tax,
          }));
      });
    },
    [cart, menuData, location_id, organisation_id]
  );

  // Prepare cart items for PayPal order
  const cartItems: CartItem[] = useMemo(() => {
    return Object.keys(cart)
      .map((cartKey) => {
        const [productId, modifiersKey] = cartKey.split(':');
        const modifiersArray = parseModifiersKey(modifiersKey);
        const product = menuData?.categories
          .flatMap((category: any) =>
            category.category_type === CategoryType.DISH ? category.dish_assignments.map((item: any) => item.dish) : category.drink_assignments.map((item: any) => item.drink)
          )
          .find((item: any) => item.id === productId);

        if (!product) return null;

        const selectedModifiers = product.modifier_assignments
          .filter((mod: any) => modifiersArray.includes(mod.modifier.name))
          .map((mod: any) => ({
            id: mod.modifier.id,
            name: mod.modifier.name,
            price: mod.modifier.price,
            tax: mod.modifier.tax,
          }));

        const itemPrice = calculateItemTotalPrice({ price: product.price, modifiers: selectedModifiers });

        return {
          id: product.id,
          name: product.name,
          quantity: cart[cartKey],
          unit_amount: {
            currency_code: menuData?.location_info.currency,
            value: (itemPrice / 100).toFixed(2),
          },
          modifiers: selectedModifiers,
          price: itemPrice,
          tax: product.tax,
        };
      })
      .filter((item) => item !== null) as CartItem[];
  }, [cart, menuData]);

  const totalPrice = cartItems.reduce((sum, item) => sum + item.price * item.quantity, 0);
  const totalTax = cartItems.reduce((sum, item) => sum + item.tax * item.quantity, 0);
  const currency = menuData?.location_info.currency;

  // Function to create order on the server
  const createOrderFunc = async (source: string) => {
    try {
      const order = JSON.stringify({
        cart: cartItems,
        merchantId: menuData.location_info.integrations[0].payment.merchantId,
        brandName: menuData.location_info.name,
        threeDSecurePreference: threeDSecurePreference,
        is3DEnabled: is3DEnabled,
        advancedSettings: advancedSettings,
        source: source,
      });
      const orderData = await createOrder(order);
      return orderData.id;
    } catch (error) {
      console.error('Failed to create order:', error);
      return null;
    }
  };

  // Function to process the order after payment
  const processOrderAfterPayment = async (orderData: any) => {
    const dishItems = buildItems(CategoryType.DISH);
    const drinkItems = buildItems(CategoryType.DRINK);

    const totalDishItemsPrice = dishItems.reduce((sum, item) => sum + item.price, 0);
    const totalDrinkItemsPrice = drinkItems.reduce((sum, item) => sum + item.price, 0);
    const totalDishItemsTax = dishItems.reduce((sum, item) => sum + item.tax, 0);
    const totalDrinkItemsTax = drinkItems.reduce((sum, item) => sum + item.tax, 0);

    const totalPrice = totalDishItemsPrice + totalDrinkItemsPrice;
    const totalTax = totalDishItemsTax + totalDrinkItemsTax;
    const whenFor = timeOption === 'schedule' ? combineDayAndTime(scheduledDay, scheduledTime) : new Date().toISOString();

    const orderPayload = {
      created_at: new Date().toISOString(),
      location_id,
      organisation_id,
      ...(orderType !== 'pickup' && { quick_sale: true }),
      paid: true,
      ...(orderType === 'dinein' && { quick_sale: false, table_number: tableNumber }),
      ...(orderType === 'pickup' && {
        when_for: whenFor,
        customer_name: customerName,
        customer_phone: customerPhone,
        delivery_address: deliveryAddress,
        delivery_postcode: deliveryPostcode,
      }),
      ...(orderType === 'pickup' && timeOption === 'schedule' && { takeaway_type: 'PREORDER' }),
      total: totalPrice,
      tax: totalTax,
      ...(dishItems.length > 0 && { dish_items: { data: dishItems } }),
      ...(drinkItems.length > 0 && { drink_items: { data: drinkItems } }),
    };

    const paymentData = {
      total: totalPrice,
      tax: totalTax,
      tip: 0,
      created_at: new Date().toISOString(),
      location_id,
      organisation_id,
      payment_method: 'ONLINE',
      api_response: JSON.stringify(orderData),
    };

    if (orderType === 'pickup') {
      const res = await createTakeAwayOrderAfterPaymentAsync(JSON.stringify(orderPayload));
      await createTakeAwayOrderPaymentAsync(
        JSON.stringify({
          ...paymentData,
          order_id: res.data.insert_orders_takeaway_one.id,
        })
      );
    } else {
      const res = await createServedOrderAfterPaymentAsync(JSON.stringify(orderPayload));
      await createServedOrderPaymentAsync(
        JSON.stringify({
          ...paymentData,
          order_id: res.data.insert_orders_served_one.id,
        })
      );
    }
  };

  // Handler for PayPal payment approval
  const onApproveFunc = async (data: any, actions: any) => {
    try {
      setIsPaying(true);

      // Attempt to capture the order
      const orderData = await captureOrder({ orderID: data.orderID, merchantId: merchantId });

      const errorDetail = orderData?.details?.[0];
      // Handle INSTRUMENT_DECLINED
      if (errorDetail?.issue === INSTRUMENT_DECLINED) {
        console.log('Instrument declined, retrying...');
        return actions.restart();
      }

      // Handle PAYER_ACTION_REQUIRED for 3DS flows
      if (errorDetail?.issue === 'PAYER_ACTION_REQUIRED') {
        console.log('Payer action required, initiating...');
        //@ts-ignore
        await window.paypal.Card().initiatePayerAction({ orderId: data.orderID });
        console.log('Payer action completed, retrying capture...');
        const updatedOrderData = await captureOrder({ orderID: data.orderID, merchantId: merchantId });

        // Process the updated order after payer action
        await processOrderAfterPayment(updatedOrderData);
        setMessage(`Transaction completed successfully: ${updatedOrderData.id}. Payment method: ${fundingSource}`);
        setOrderDetails(updatedOrderData);
        setShowThankYouModal(true);
        return;
      }

      // Handle other potential errors
      if (errorDetail) {
        throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
      }
      if (orderData.status === 'COMPLETED' && orderData.purchase_units[0].payments.captures[0].status === 'COMPLETED') {
        // If no errors, process the order normally
        await processOrderAfterPayment(orderData);
        setMessage(`Transaction completed successfully: ${orderData.id}. Payment method: ${fundingSource}`);
        setOrderDetails(orderData);
        setShowThankYouModal(true);
      } else {
        setMessage(`Transaction not completed: ${orderData.id} ${orderData.purchase_units[0].payments.captures[0].status}. Payment method: ${fundingSource}`);
        throw new Error(`Transaction not completed: ${orderData.purchase_units[0].payments.captures[0].status}`);
      }
    } catch (error) {
      console.error('Transaction failed:', error);
      setMessage('Transaction failed. Please try again.');
    } finally {
      setIsPaying(false);
    }
  };

  // Handler for PayPal payment cancellation
  const onCancelFunc = (data: any) => {
    setMessage('Payment was canceled.');
  };

  // Handler for PayPal payment errors
  const onErrorFunc = (err: any) => {
    console.error('Payment error:', err);
    setMessage('An error occurred during the transaction. Please try again.');
  };

  // Handler for card payments
  const handleCardPayment = async (cardFieldsForm: { getState: () => any; submit: () => any }) => {
    if (!cardFieldsForm) {
      alert('Unable to find card fields form');
      return;
    }
    const formState = await cardFieldsForm.getState();
    if (!formState.isFormValid) {
      alert('The payment form is invalid');
      return;
    }
    setIsPaying(true);
    try {
      await cardFieldsForm.submit();
      // setMessage('Card payment completed successfully');
    } catch (error) {
      console.error('Card payment failed:', error);
      setMessage('Card payment failed');
    } finally {
      setIsPaying(false);
    }
  };
  // Handler for card fields approval
  const onApproveCardFields = async (data: any) => {
    try {
      const orderData = await captureOrder({ orderID: data.orderID, merchantId: merchantId });

      const errorDetail = orderData?.details?.[0];
      if (errorDetail?.issue === INSTRUMENT_DECLINED) {
        throw new Error('Instrument declined, please try again');
      } else if (errorDetail) {
        throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
      } else {
        if (orderData.status === 'COMPLETED' && orderData.purchase_units[0].payments.captures[0].status === 'COMPLETED') {
          // If no errors, process the order normally
          await processOrderAfterPayment(orderData);
          setMessage(`Transaction completed successfully: ${orderData.id}. Payment method: ${fundingSource}`);
          setOrderDetails(orderData);
          setShowThankYouModal(true);
        } else {
          setMessage(`Transaction not completed: ${orderData.id} ${orderData.purchase_units[0].payments.captures[0].status}. Payment method: ${fundingSource}`);
          throw new Error(`Transaction not completed: ${orderData.purchase_units[0].payments.captures[0].status}`);
        }
      }
    } catch (error) {
      console.error('Card payment failed:', error);
      setMessage('Card payment failed');
    }
  };

  if (!threeDSecurePreference || is3DEnabled === null) {
    return <div>Loading payment options...</div>;
  }

  return (
    <>
      {merchantId ? (
        <>
          <div>
            <PayPalMarks />
            {paypal && (
              <PayPalButtons
                style={{ shape: 'rect', layout: 'vertical', color: 'gold', label: 'paypal' }}
                createOrder={() => createOrderFunc('paypal')}
                onApprove={onApproveFunc}
                onCancel={onCancelFunc}
                onError={onErrorFunc}
                onClick={(data, actions) => {
                  //@ts-ignore
                  setFundingSource(data.fundingSource);
                  return actions.resolve();
                }}
                onShippingAddressChange={async (data, actions) => {
                  const shippingAddress = data.shippingAddress;
                  // Update your application state or perform any necessary actions
                  // based on the new shipping address
                  console.log('Shipping address changed:', shippingAddress);
                  setMessage(`Shipping address changed: ${shippingAddress}`);
                  // If you need to update the order (e.g., shipping cost changed), you can use:
                  // return actions.order.patch([{
                  //   op: 'replace',
                  //   path: '/purchase_units/@reference_id=="default"/amount',
                  //   value: {
                  //     currency_code: 'USD',
                  //     value: '30.00' // New total amount
                  //   }
                  // }]);

                  // If the new address is not acceptable, you can reject it:
                  // return actions.reject();

                  // If the address is acceptable and no changes are needed, simply return:
                  return Promise.resolve();
                }}
              />
            )}
            {cards && (
              <PayPalCardFieldsProvider
                createOrder={() => createOrderFunc('card')}
                onApprove={onApproveCardFields}
                onError={(err) => {
                  console.error('CardFields error:', err);
                  setMessage('An error occurred during the transaction. Please try again.');
                }}>
                <CardFieldsFormComponent
                  isPaying={isPaying}
                  handleCardPayment={handleCardPayment}
                  //@ts-ignore
                  onError={(err) => {
                    console.error('CardFields error:', err);
                    setMessage('An error occurred during the transaction. Please try again.');
                  }}
                />
              </PayPalCardFieldsProvider>
            )}

            {message && <div className="message">{message}</div>}
            {googlePay && (
              <GooglePayButton createOrderFunc={createOrderFunc} onApproveFunc={onApproveFunc} cartItems={cartItems} totalPrice={totalPrice} tax={totalTax} currency={currency} />
            )}
            {applePay && (
              <ApplePayButton createOrderFunc={createOrderFunc} onApproveFunc={onApproveFunc} cartItems={cartItems} totalPrice={totalPrice} tax={totalTax} currency={currency} />
            )}
          </div>
          {showThankYouModal && orderDetails && (
            <ThankYouModal
              orderDetails={orderDetails}
              onClose={() => {
                setShowThankYouModal(false);
                onClose();
              }}
            />
          )}
        </>
      ) : (
        <div>PayPal onboarding was not completed. Please complete onboarding in the integration settings.</div>
      )}
    </>
  );
};

export default PayPalCheckout;

const CardFieldsFormComponent = ({ isPaying, handleCardPayment }: any) => {
  const { cardFieldsForm } = usePayPalCardFields();

  return (
    <div>
      <PayPalCardFieldsForm />
      <button onClick={() => handleCardPayment(cardFieldsForm)} disabled={isPaying} className={isPaying ? 'btn' : 'btn btn-primary'}>
        {isPaying ? <div className="spinner tiny" /> : 'Pay with Card'}
      </button>
    </div>
  );
};
