import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, List, ListItem, ListItemText } from '@material-ui/core';

interface ThankYouModalProps {
  orderDetails: {
    id: string;
    status: string;
    // purchase_units: Array<{
    //   amount: { value: string; currency_code: string };
    //   items: Array<{
    //     name: string;
    //     quantity: number;
    //     unit_amount: { value: string; currency_code: string };
    //   }>;
    // }>;
    purchase_units: any;
  };
  onClose: () => void;
}

const ThankYouModal: React.FC<ThankYouModalProps> = ({ orderDetails, onClose }) => {
  const items = orderDetails?.purchase_units?.[0]?.items || [];
  const totalAmount = orderDetails?.purchase_units?.[0]?.payments?.captures[0];

  return (
    <Dialog open={true} onClose={onClose} fullWidth>
      <DialogTitle>Thank You for Your Purchase!</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Your order has been successfully processed.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Order Summary
        </Typography>
        <Typography variant="body2" gutterBottom>
          <strong>Order ID:</strong> {orderDetails.id}
        </Typography>
        <Typography variant="body2" gutterBottom>
          <strong>Status:</strong> {orderDetails.status}
        </Typography>
        <Typography variant="h6" gutterBottom>
          Items Purchased:
        </Typography>
        <List>
          {items.map((item: any) => (
            <ListItem key={item.name}>
              <ListItemText primary={`${item.name} x ${item.quantity}`} secondary={`${item.unit_amount.value} ${item.unit_amount.currency_code}`} />
            </ListItem>
          ))}
        </List>
        <Typography variant="body2" gutterBottom>
          <strong>Total Amount:</strong> {totalAmount.value} {totalAmount.currency_code}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ThankYouModal;
