import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { Action, OrderDetails } from '../State';
import PayPalCheckout from '../../../Common/components/Checkout';
import { getThreeDSecureSettings } from '../../../../../../components/Global/Location/Common/components/StepIntegrations/components/ThreeDSecureSettings/queries';
import { getPaymentSettings } from '../../../../../../components/Global/Location/Common/components/StepIntegrations/components/PayPalLink/queries';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

const useStyles = makeStyles((theme) => ({
  checkoutContainer: {
    padding: theme.spacing(2),
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  orderSummary: {
    marginBottom: theme.spacing(2),
  },
  totalPrice: {
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
  },
}));

interface Modifier {
  id: string;
  name: string;
  price: number;
}

interface MenuItemData {
  id: string;
  name: string;
  price: number;
  modifier_assignments: { modifier: Modifier }[];
}

interface CartItem {
  cartKey: string;
  productId: string;
  modifiersKey: string;
  name: string;
  description: string;
  price: number;
  quantity: number;
}

interface CheckoutProps {
  orderDetails: OrderDetails;
  dispatch: React.Dispatch<Action>;
  totalPrice: number;
  onClose: () => void;
  cartItems: CartItem[];
  cart: { [key: string]: number };
  menuData: any; // Replace with actual type
  createOrder: (request: string) => Promise<any>;
  captureOrder: (orderId: string) => Promise<any>;
  createServedOrderAfterPaymentAsync: (order: any) => Promise<any>;
  createTakeAwayOrderAfterPaymentAsync: (order: any) => Promise<any>;
  createTakeAwayOrderPaymentAsync: (order: any) => Promise<any>;
  createServedOrderPaymentAsync: (order: any) => Promise<any>;
  location_id: string;
  organisation_id: string;
}

const { REACT_APP_PAYPAL_CLIENT_ID, REACT_APP_PAYPAL_BN_CODE } = process.env;

const Checkout: React.FC<CheckoutProps> = ({
  orderDetails,
  dispatch,
  totalPrice,
  onClose,
  cartItems,
  cart,
  menuData,
  createOrder,
  captureOrder,
  createServedOrderAfterPaymentAsync,
  createTakeAwayOrderAfterPaymentAsync,
  createTakeAwayOrderPaymentAsync,
  createServedOrderPaymentAsync,
  location_id,
  organisation_id,
}) => {
  const initialOptions = {
    clientId: REACT_APP_PAYPAL_CLIENT_ID ?? '',
    ...(menuData?.location_info?.integrations[0]?.payment?.merchantId && { merchantId: menuData?.location_info?.integrations[0]?.payment?.merchantId }),
    currency: 'GBP',
    components: 'buttons,card-fields,marks,applepay,googlepay',
    intent: 'capture',
    locale: 'en_GB',
    dataPartnerAttributionId: REACT_APP_PAYPAL_BN_CODE ?? 'AccentPOS_PPCP',
    disableFunding: 'bancontact,blik,sofort,mybank,ideal,giropay,eps,sepa',
  };
  const classes = useStyles();

  const [showPayPalCheckout, setShowPayPalCheckout] = useState(false);
  const [threeDSecureSettings, setThreeDSecureSettings] = useState(null);
  const [paymentSettings, setPaymentSettings] = useState(null);

  const [days, setDays] = useState<Date[]>([]);
  const [scheduledDay, setScheduledDay] = useState<string>('');
  const [scheduledTime, setScheduledTime] = useState<string>('');
  const [timeSlotsWithLimitCheck, setTimeSlotsWithLimitCheck] = useState<{ slot: string; isOverLimit: boolean }[]>([]);

  useEffect(() => {
    if (menuData?.location_info) {
      const numDays = menuData.location_info.preorder_days_limit ?? 5;
      const daysArray = getDaysArray(numDays);
      setDays(daysArray);
      setScheduledDay(daysArray[0].toISOString().split('T')[0]);
    }
  }, [menuData]);

  useEffect(() => {
    if (menuData?.location_info && scheduledDay) {
      const currentDayDate = new Date(scheduledDay);
      const preorderHours = getDayHours(menuData.location_info.ordering_system_preorder_hours ?? [], currentDayDate);
      const preorderTimeRange = getPreorderTimeRange(preorderHours);

      if (preorderTimeRange) {
        const currentTime = new Date().toLocaleTimeString('en-GB', {
          hour12: false,
          hour: '2-digit',
          minute: '2-digit',
        });
        const isToday = scheduledDay === new Date().toISOString().split('T')[0];
        const orderInterval = menuData.location_info.order_interval ?? 15;

        const timeSlots = filterTimeSlots(preorderTimeRange.open, preorderTimeRange.close, isToday ? currentTime : null, orderInterval);

        const timeSlotsWithLimit = timeSlots.map((slot) => {
          const whenFor = combineDayAndTime(scheduledDay, slot);
          const existingOrdersTotalScore = calculateTotalScoreForOrders(menuData.orders_takeaway ?? [], whenFor);
          const currentOrderTotalScore = calculateTotalScoreOfCurrentOrder(cart, menuData);
          const isOverLimit = existingOrdersTotalScore + currentOrderTotalScore > (menuData.location_info.preorder_pacing_limit ?? 0);
          return { slot, isOverLimit };
        });

        setTimeSlotsWithLimitCheck(timeSlotsWithLimit);
      } else {
        setTimeSlotsWithLimitCheck([]);
      }
    }
  }, [menuData, scheduledDay, cart]);

  const fetchThreeDSecurePreference = async () => {
    try {
      const data = await getThreeDSecureSettings(location_id);
      setThreeDSecureSettings(data);
    } catch (error) {
      console.error('Failed to fetch 3D Secure settings:', error);
    }
  };
  const fetchPaymentPreference = async () => {
    try {
      const data = await getPaymentSettings(location_id);
      setPaymentSettings(data);
    } catch (error) {
      console.error('Failed to fetch payment settings:', error);
    }
  };

  const handleProceedToPayment = async () => {
    // Validate order details
    if (!isOrderReady()) {
      alert('Please complete all required fields before proceeding to payment.');
      return;
    }
    // Fetch 3D Secure settings
    await fetchThreeDSecurePreference();
    await fetchPaymentPreference();
    // Show PayPalCheckout component
    setShowPayPalCheckout(true);
  };

  const handleOrderTypeChange = (event: React.MouseEvent<HTMLElement>, newOrderType: 'dinein' | 'delivery' | 'pickup' | null) => {
    if (newOrderType !== null) {
      dispatch({ type: 'UPDATE_ORDER_DETAILS', payload: { orderType: newOrderType } });
    }
  };

  const isOrderReady = () => {
    if (!orderDetails.orderType) {
      return false;
    }
    if (orderDetails.orderType === 'pickup') {
      if (orderDetails.timeOption === 'schedule') {
        if (!scheduledDay || !scheduledTime) {
          return false;
        }
        // Check if the selected time slot is over limit
        const selectedSlot = timeSlotsWithLimitCheck.find((slot) => slot.slot === scheduledTime);
        if (selectedSlot?.isOverLimit) {
          return false;
        }
      }
    }
    return true;
  };

  return (
    <PayPalScriptProvider options={initialOptions} data-partner-attribution-id={process.env.REACT_APP_PAYPAL_BN_CODE ?? 'AccentPOS_PPCP'}>
      <div>
        <DialogTitle disableTypography>
          <Typography variant="h6">Checkout</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <Box className={classes.checkoutContainer}>
          {/* Order Summary */}
          <Typography variant="h6" gutterBottom>
            Order Summary
          </Typography>
          <List className={classes.orderSummary}>
            {cartItems.map((item) => (
              <ListItem key={item.cartKey}>
                <ListItemText primary={`${item.quantity} x ${item.name}`} secondary={item.description} />
                <ListItemSecondaryAction>
                  <Typography variant="body2">£{(item.price * item.quantity).toFixed(2)}</Typography>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          <Typography variant="body1" className={classes.totalPrice}>
            Total: £{totalPrice.toFixed(2)}
          </Typography>

          {/* Order Type Selection */}
          <Box mt={2}>
            <Typography variant="h6">Select Order Type</Typography>
            <ToggleButtonGroup value={orderDetails.orderType} exclusive onChange={handleOrderTypeChange} aria-label="order type" style={{ marginTop: '8px' }}>
              <ToggleButton value="dinein" aria-label="dine-in">
                Dine-in
              </ToggleButton>
              <ToggleButton value="delivery" aria-label="delivery">
                Delivery
              </ToggleButton>
              <ToggleButton value="pickup" aria-label="pickup">
                Pickup
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          {/* Conditional Inputs Based on Order Type */}
          {orderDetails.orderType === 'dinein' && (
            <TextField
              label="Table Number"
              variant="outlined"
              fullWidth
              className={classes.formControl}
              value={orderDetails.tableNumber || ''}
              onChange={(e) =>
                dispatch({
                  type: 'UPDATE_ORDER_DETAILS',
                  payload: { tableNumber: e.target.value ? parseInt(e.target.value) : null },
                })
              }
            />
          )}

          {orderDetails.orderType === 'pickup' && (
            <Box mt={2}>
              <Typography variant="h6">Pickup Options</Typography>
              <ToggleButtonGroup
                value={orderDetails.timeOption}
                exclusive
                onChange={(e: any, newValue: any) =>
                  dispatch({
                    type: 'UPDATE_ORDER_DETAILS',
                    payload: { timeOption: newValue },
                  })
                }
                aria-label="time option"
                style={{ marginTop: '8px' }}>
                <ToggleButton value="standard" aria-label="standard">
                  Standard
                </ToggleButton>
                <ToggleButton value="schedule" aria-label="schedule">
                  Schedule
                </ToggleButton>
              </ToggleButtonGroup>

              <Box mt={2}>
                <Typography variant="h6">Customer Details</Typography>
            
                <Box className={classes.formControl}>
                  <TextField
                    label="Customer Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={orderDetails.customerName}
                    onChange={(e) =>
                      dispatch({
                        type: 'UPDATE_ORDER_DETAILS',
                        payload: { customerName: e.target.value },
                      })
                    }
                  />
                </Box>
                <Box className={classes.formControl}>
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={orderDetails.customerPhone}
                    onChange={(e) =>
                      dispatch({
                        type: 'UPDATE_ORDER_DETAILS',
                        payload: { customerPhone: e.target.value },
                      })
                    }
                  />
                </Box>
                <Box className={classes.formControl}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={orderDetails.customerEmail}
                    onChange={(e) =>
                      dispatch({
                        type: 'UPDATE_ORDER_DETAILS',
                        payload: { customerEmail: e.target.value },
                      })
                    }
                    required
                  />
                </Box>
              </Box>

              {orderDetails.timeOption === 'schedule' && (
                <Box mt={2}>
                  {/* Day Selection */}
                  <FormControl variant="outlined" fullWidth className={classes.formControl}>
                    <InputLabel id="scheduled-day-label">Select Day</InputLabel>
                    <Select
                      labelId="scheduled-day-label"
                      label="Select Day"
                      value={scheduledDay}
                      onChange={(e) => {
                        setScheduledDay(e.target.value as string);
                        dispatch({
                          type: 'UPDATE_ORDER_DETAILS',
                          payload: { scheduledDay: e.target.value as string },
                        });
                      }}>
                      {days.map((date, index) => (
                        <MenuItem value={date.toISOString().split('T')[0]} key={index}>
                          {formattedDay(date)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* Time Selection */}
                  <FormControl variant="outlined" fullWidth className={classes.formControl}>
                    <InputLabel id="scheduled-time-label">Select Time</InputLabel>
                    <Select
                      labelId="scheduled-time-label"
                      label="Select Time"
                      value={scheduledTime}
                      onChange={(e) => {
                        setScheduledTime(e.target.value as string);
                        dispatch({
                          type: 'UPDATE_ORDER_DETAILS',
                          payload: { scheduledTime: e.target.value as string },
                        });
                      }}>
                      {timeSlotsWithLimitCheck.map(({ slot, isOverLimit }, index) => (
                        <MenuItem value={slot} key={index} disabled={isOverLimit}>
                          {slot} {isOverLimit ? '(Over Limit)' : ''}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}
            </Box>
          )}
              {orderDetails.orderType === 'delivery' && (
                  <>
                    <Box className={classes.formControl}>
                      <TextField
                        fullWidth
                        label="Delivery Address"
                        value={orderDetails.deliveryAddress}
                        onChange={(e) =>
                          dispatch({
                            type: 'UPDATE_ORDER_DETAILS',
                            payload: { deliveryAddress: e.target.value },
                          })
                        }
                        required
                      />
                    </Box>
                    <Box className={classes.formControl}>
                      <TextField
                        fullWidth
                        label="Postcode"
                        value={orderDetails.deliveryPostcode}
                        onChange={(e) =>
                          dispatch({
                            type: 'UPDATE_ORDER_DETAILS',
                            payload: { deliveryPostcode: e.target.value },
                          })
                        }
                        required
                      />
                    </Box>
                  </>
                )}
          {!showPayPalCheckout && (
            <Button variant="contained" color="primary" onClick={handleProceedToPayment} disabled={!isOrderReady()}>
              Proceed to Payment
            </Button>
          )}

          {/* Place Order Button */}
          {showPayPalCheckout && paymentSettings && threeDSecureSettings && (
            <PayPalCheckout
              cart={cart}
              menuData={menuData}
              createOrder={createOrder}
              captureOrder={captureOrder}
              createServedOrderAfterPaymentAsync={createServedOrderAfterPaymentAsync}
              createTakeAwayOrderAfterPaymentAsync={createTakeAwayOrderAfterPaymentAsync}
              createTakeAwayOrderPaymentAsync={createTakeAwayOrderPaymentAsync}
              createServedOrderPaymentAsync={createServedOrderPaymentAsync}
              location_id={location_id}
              organisation_id={organisation_id}
              orderType={orderDetails.orderType!}
              timeOption={orderDetails.timeOption}
              tableNumber={orderDetails.tableNumber}
              customerName={orderDetails.customerName}
              customerPhone={orderDetails.customerPhone}
              deliveryAddress={orderDetails.deliveryAddress}
              deliveryPostcode={orderDetails.deliveryPostcode}
              // tableNumber={null}
              scheduledDay={scheduledDay}
              scheduledTime={scheduledTime}
              onClose={onClose}
              paymentSettings={paymentSettings!}
              //@ts-ignore
              threeDSecureSettings={threeDSecureSettings}
              merchantId={initialOptions?.merchantId ?? null}
            />
          )}
        </Box>
      </div>
    </PayPalScriptProvider>
  );
};

// Utility functions

interface TimeRange {
  day?: string;
  open: string;
  close: string;
}

// Get array of dates for the next N days
const getDaysArray = (numDays: number): Date[] => {
  const today = new Date();
  return Array.from({ length: numDays }, (_, i) => {
    const date = new Date(today);
    date.setDate(today.getDate() + i);
    return date;
  });
};

// Get the current day of the week as a string
const getCurrentDay = (date: Date): string => {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return daysOfWeek[date.getDay()];
};

// Check if a time is within a given time range
const isTimeInRange = (currentTime: string, startTime: string, endTime: string): boolean => {
  const current = new Date(`1970-01-01T${currentTime}:00Z`);
  const start = new Date(`1970-01-01T${startTime}:00Z`);
  const end = new Date(`1970-01-01T${endTime}:00Z`);
  return current >= start && current <= end;
};

// Get the open hours for a specific day
const getDayHours = (hoursArray: TimeRange[], date: Date): TimeRange | null => {
  const currentDay = getCurrentDay(date);
  return hoursArray.find((day) => day.day === currentDay) || null;
};

// Check if the store is currently open
const isStoreOpen = (openHours: TimeRange | null): boolean => {
  if (!openHours) return true;
  const currentTime = new Date().toLocaleTimeString('en-GB', { hour12: false, hour: '2-digit', minute: '2-digit' });
  return isTimeInRange(currentTime, openHours.open, openHours.close);
};

// Get the preorder time range for a specific day
const getPreorderTimeRange = (preorderHours: TimeRange | null): TimeRange | null => {
  if (!preorderHours) return null;
  return { open: preorderHours.open, close: preorderHours.close };
};

// Combine day and time into a datetime string
const combineDayAndTime = (day: string, time: string): string => {
  return `${day}T${time}:00Z`;
};

// Filter time slots based on open and close times, and current time if provided
const filterTimeSlots = (open: string, close: string, currentTime: string | null = null, interval: number = 15): string[] => {
  const slots: string[] = [];
  const start = new Date(`1970-01-01T${open}:00Z`);
  const end = new Date(`1970-01-01T${close}:00Z`);

  let currentSlot = new Date(start);

  while (currentSlot <= end) {
    const slotString = currentSlot.toTimeString().slice(0, 5);
    if (!currentTime || slotString >= currentTime) {
      slots.push(slotString);
    }
    currentSlot.setMinutes(currentSlot.getMinutes() + interval);
  }

  return slots;
};

// Calculate the total score for existing orders at a specific time slot
const calculateTotalScoreForOrders = (orders: any[], whenFor: string): number => {
  return orders
    .filter((order) => {
      const orderDate = new Date(order.when_for);
      const whenForDate = new Date(whenFor);
      return orderDate.getTime() === whenForDate.getTime();
    })
    .reduce((totalScore, order) => {
      const dishScore = order.dish_items.reduce((sum: number, dish: any) => sum + (dish.item.score || 0), 0);
      const drinkScore = order.drink_items.reduce((sum: number, drink: any) => sum + (drink.item.score || 0), 0);
      return totalScore + dishScore + drinkScore;
    }, 0);
};

// Calculate the total score of the current order
const calculateTotalScoreOfCurrentOrder = (cart: { [key: string]: number }, menuData: any): number => {
  return Object.keys(cart).reduce((totalScore, cartKey) => {
    const [productId] = cartKey.split(':');
    const product = menuData?.categories
      .flatMap((category: any) =>
        category.category_type === 'DISH' ? category.dish_assignments.map((item: any) => item.dish) : category.drink_assignments.map((item: any) => item.drink)
      )
      .find((item: any) => item.id === productId);

    if (!product) return totalScore;

    return totalScore + (product.score || 0) * cart[cartKey];
  }, 0);
};

const formattedDay = (date: Date): string => {
  return date.toLocaleDateString('en-US', {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
  });
};

export default Checkout;
