import React from 'react';
import { Backdrop, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: '#fff',
    flexDirection: 'column',
  },
  message: {
    marginTop: theme.spacing(2),
  },
}));

// @ts-ignore
const LoadingOverlay = ({ message }) => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open>
      <CircularProgress color="inherit" />
      <Typography variant="h6" className={classes.message}>
        {message}
      </Typography>
    </Backdrop>
  );
};

export default LoadingOverlay;
