import { webhookClient } from '../../../../../../../../utils/webhookClient';

export const getTransactions = async (merchantId: string): Promise<any> => {
  const response = await webhookClient.getAnon(`paypal/transactions/${merchantId}`);
  return response || [];
};

// Function to refund a transaction
export const refundTransaction = async (captureId: any, amount: any, invoiceId: any, merchantId: any) => {
  const response = await webhookClient.postAnon(
    `paypal/refund`,
    JSON.stringify({
      captureId,
      amount,
      invoiceId,
      merchantId,
    })
  );
  return response;
};
