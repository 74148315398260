import React from 'react';
import { useQuery, useMutation } from 'react-query';
import { getTransactions, refundTransaction } from './queries';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, CircularProgress, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

interface Transaction {
  id: string;
  amount: number;
  status: string;
  date: string;
  capture_id: string;
  invoice_id: string;
  order_number: string; // Include order number in the transaction
}

interface TransactionListProps {
  merchantId: string;
  locationId: string;
}

const TransactionList: React.FC<TransactionListProps> = ({ merchantId, locationId }) => {
  const { data: transactions, isLoading, error } = useQuery<Transaction[]>(['transactions', locationId], () => getTransactions(locationId));

  const mutation = useMutation(
    ({ captureId, amount, invoiceId }: { captureId: string; amount: string; invoiceId: string }) => refundTransaction(captureId, amount, invoiceId, merchantId),
    {
      onSuccess: () => {
        alert('Refund processed successfully.');
      },
      onError: () => {
        alert('Failed to process refund. Please try again.');
      },
    }
  );

  const handleRefund = (transaction: Transaction) => {
    const confirmRefund = window.confirm(`Are you sure you want to refund £${(transaction.amount / 100).toFixed(2)}?`);
    if (confirmRefund) {
      mutation.mutate({
        captureId: transaction.capture_id,
        amount: (transaction.amount / 100).toFixed(2),
        invoiceId: transaction.invoice_id,
      });
    }
  };
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Transactions
      </Typography>
      {isLoading && <CircularProgress />}
      {error && <Alert severity="error">Error loading transactions: {(error as Error).message}</Alert>}
      {!isLoading && transactions && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Transaction ID</TableCell>
                <TableCell>Invoice ID</TableCell>
                <TableCell>Order Number</TableCell>
                <TableCell>Amount (£)</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((tx) => (
                <TableRow key={tx.id}>
                  <TableCell>{tx.id}</TableCell>
                  <TableCell>{tx.invoice_id}</TableCell>
                  <TableCell>{tx.order_number}</TableCell>
                  <TableCell>{(tx.amount / 100).toFixed(2)}</TableCell>
                  <TableCell>{tx.status}</TableCell>
                  <TableCell>{new Date(tx.date).toLocaleString()}</TableCell>
                  <TableCell>
                    {tx.status === 'COMPLETED' && (
                      <Button variant="contained" color="secondary" onClick={() => handleRefund(tx)} disabled={mutation.isLoading}>
                        {mutation.isLoading ? <CircularProgress size={20} /> : 'Refund'}
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default TransactionList;
