import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import Dashboard from '../../../layouts/Dashboard';
import EditLocation from '../../../components/Global/Location/Edit';
import { homeInitQuery, Location } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const Home = ({ classes, history }: Props): React.ReactElement => {
  const [locationData, setLocationData] = useState<Location>({
    id: '',
    name: '',
    email: '',
    phone: '',
    website: '',
    tax_number: '',
    currency: '',
    locale: '',
    number_of_tables: 0,
    location_type: '',
    address_line_1: '',
    address_line_2: '',
    address_city: '',
    address_country: '',
    address_postcode: '',
    can_manage_menus: false,
    has_kitchen_printer: false,
    has_bar_printer: false,
    has_staff: false,
    has_station_printers: false,
    service_charge: null,
    idle_timeout: 30,
    location_menu_id: undefined,
    ordering_system_primary_color: undefined,
    ordering_system_secondary_color: undefined,
    header_image_added: false,
    logo_image_added: false,
    ordering_system_open_hours: null,
    ordering_system_preorder_hours: null,
    preorder_days_limit: 0,
    preorder_pacing_limit: 0,
    order_interval: 0,
    inventory_restock_type: '',
    organisation_id: '',
    devices: [],
    voucher_codes: [],
  });

  const { data: homeInitData } = useQuery(homeInitQuery(), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && homeInitData && homeInitData.locations[0]) {
      setLocationData(homeInitData.locations[0]);
    }
    return () => {
      mounted = false;
    };
  }, [homeInitData]);

  const handleFinish = () => {
    history.push('/loc/home');
  };

  const handleCreateUser = () => {
    history.push('/loc/users/create');
  };

  const handleViewUser = (id: string) => {
    history.push(`/loc/users/${id}`);
  };

  const handleEditUser = (id: string) => {
    history.push(`/loc/users/${id}/edit`);
  };

  const handleCreatePrinter = () => {
    history.push('/loc/printers/create');
  };

  const handleEditPrinter = (id: string) => {
    history.push(`/loc/printers/${id}/edit`);
  };

  const handleCreateStaff = () => {
    history.push('/loc/staff/create');
  };

  const handleViewStaff = (id: string) => {
    history.push(`/loc/staff/${id}`);
  };

  const handleEditStaff = (id: string) => {
    history.push(`/loc/staff/${id}/edit`);
  };

  const handleCreateVoucher = () => {
    history.push('/loc/vouchers/create');
  };

  const handleEditVoucher = (id: string) => {
    history.push(`/loc/vouchers/${id}/edit`);
  };

  const [breadcrumbsUpdated, setBreadcrumbsUpdated] = useState(true);
  const [breadcrumbs, setBreadcrumbs] = useState([{ label: 'Home' }]);

  return (
    <EditLocation
      classes={classes}
      location_id={locationData.id}
      organisation_id={locationData.organisation_id}
      handleFinish={handleFinish}
      setBreadcrumbs={setBreadcrumbs}
      breadcrumbs={breadcrumbs}
      breadcrumbsUpdated={breadcrumbsUpdated}
      handleCreateUser={handleCreateUser}
      handleViewUser={handleViewUser}
      handleEditUser={handleEditUser}
      handleCreatePrinter={handleCreatePrinter}
      handleEditPrinter={handleEditPrinter}
      printers={locationData.devices}
      handleCreateStaff={handleCreateStaff}
      handleViewStaff={handleViewStaff}
      handleEditStaff={handleEditStaff}
      handleCreateVoucher={handleCreateVoucher}
      handleEditVoucher={handleEditVoucher}
      currency={locationData.currency}
      locale={locationData.locale}
      voucher_codes={locationData.voucher_codes}
    />
  );
};

export default withRouter(withStyles(styles)(Home));
