import { webhookClient } from '../../../../../../../../utils/webhookClient';
import { useMutation, useQuery } from 'react-query';
import { getThreeDSecureSettings, updateThreeDSecureSettings } from '../ThreeDSecureSettings/queries';

export const generateOnboardingLink = async (locationId: any, product: string, partnerLogo: string, returnUrl: string) => {
  const response = await webhookClient.postAnon(`paypal/partner-referral`, JSON.stringify({ locationId, product, partnerLogo, returnUrl }));
  return response;
};

export const manualOnboardingCheck = async (locationId: any) => {
  const response = await webhookClient.getAnon(`paypal/check-integration/${locationId}`);
  return response;
};

// Function to get account status
export const getAccountStatus = async (merchantId: any) => {
  const response = await webhookClient.getAnon(`paypal/account-status/${merchantId}`);
  return response;
};

// Function to disconnect PayPal account
export const disconnectPayPalAccount = async (merchantId: any) => {
  const response = await webhookClient.postAnon(`paypal/disconnect`, JSON.stringify({ merchantId }));
  return response;
};
export const getPaymentSettings = async (locationId: any) => {
  const response = await webhookClient.getAnon(`paypal/payment-settings/${locationId}`);
  return response;
};

// Function to update 3D Secure settings
export const updatePaymentSettings = async (locationId: string, settings: { paymentSettings: { applePay: boolean; googlePay: boolean } }): Promise<any> => {
  const response = await webhookClient.postAnon(`paypal/payment-settings/${locationId}`, JSON.stringify(settings));
  return response;
};

export const useGetPaymentSettings = (locationId: string) => {
  return useQuery(['payment-settings', locationId], () => getPaymentSettings(locationId));
};

export const useUpdatePaymentSettings = (locationId: string) => {
  return useMutation((settings: { paymentSettings: { applePay: boolean; googlePay: boolean } }) => updatePaymentSettings(locationId, settings), {
    mutationKey: 'payment-settings',
  });
};
