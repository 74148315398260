import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import Dashboard from '../../../../layouts/Dashboard';
import EditLocation from '../../../../components/Global/Location/Edit';
import { selectLocationInitQuery, Location } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  location_id: string;
  organisation_id: string;
}

const OrgViewLocation = ({ classes, history }: Props): React.ReactElement => {
  const { location_id, organisation_id } = useParams<UrlParams>();

  const [locationData, setLocationData] = useState<Location>({
    id: '',
    name: '',
    email: '',
    phone: '',
    website: '',
    tax_number: '',
    currency: '',
    locale: '',
    number_of_tables: 0,
    location_type: '',
    address_line_1: '',
    address_line_2: '',
    address_city: '',
    address_country: '',
    address_postcode: '',
    can_manage_menus: false,
    has_kitchen_printer: false,
    has_bar_printer: false,
    has_staff: false,
    has_station_printers: false,
    service_charge: null,
    idle_timeout: 30,
    location_menu_id: undefined,
    ordering_system_primary_color: undefined,
    ordering_system_secondary_color: undefined,
    header_image_added: false,
    logo_image_added: false,
    ordering_system_open_hours: null,
    ordering_system_preorder_hours: null,
    preorder_days_limit: 0,
    preorder_pacing_limit: 0,
    order_interval: 0,
    inventory_restock_type: '',
    organisation_id: '',
    devices: [],
    voucher_codes: [],
  });

  const { data: locationInitData } = useQuery(selectLocationInitQuery(location_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && locationInitData && locationInitData.locations_by_pk) {
      setLocationData(locationInitData.locations_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [locationInitData]);

  const handleFinish = () => {
    history.push(`/org/locations`);
  };

  const handleCreateUser = () => {
    history.push(`/org/locations/${location_id}/users/create`);
  };

  const handleViewUser = (id: string) => {
    history.push(`/org/locations/${location_id}/users/${id}`);
  };

  const handleEditUser = (id: string) => {
    history.push(`/org/locations/${location_id}/users/${id}/edit`);
  };

  const handleCreatePrinter = () => {
    history.push(`/org/locations/${location_id}/printers/create`);
  };

  const handleEditPrinter = (id: string) => {
    history.push(`/org/locations/${location_id}/printers/${id}/edit`);
  };

  const handleCreateStaff = () => {
    history.push(`/org/locations/${location_id}/staff/create`);
  };

  const handleViewStaff = (id: string) => {
    history.push(`/org/locations/${location_id}/staff/${id}`);
  };

  const handleEditStaff = (id: string) => {
    history.push(`/org/locations/${location_id}/staff/${id}/edit`);
  };

  const handleCreateVoucher = () => {
    history.push(`/org/locations/${location_id}/vouchers/create`);
  };

  const handleEditVoucher = (id: string) => {
    history.push(`/org/locations/${location_id}/vouchers/${id}/edit`);
  };

  const [breadcrumbsUpdated, setBreadcrumbsUpdated] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      label: 'Locations',
      link: '/org/locations',
    },
    { label: locationData.name || 'Loading...' },
  ]);

  return (
    <EditLocation
      classes={classes}
      location_id={location_id}
      organisation_id={organisation_id}
      handleFinish={handleFinish}
      setBreadcrumbs={setBreadcrumbs}
      breadcrumbs={breadcrumbs}
      breadcrumbsUpdated={breadcrumbsUpdated}
      handleCreateUser={handleCreateUser}
      handleViewUser={handleViewUser}
      handleEditUser={handleEditUser}
      handleCreatePrinter={handleCreatePrinter}
      handleEditPrinter={handleEditPrinter}
      printers={locationData.devices}
      handleCreateStaff={handleCreateStaff}
      handleViewStaff={handleViewStaff}
      handleEditStaff={handleEditStaff}
      handleCreateVoucher={handleCreateVoucher}
      handleEditVoucher={handleEditVoucher}
      currency={locationData.currency}
      locale={locationData.locale}
      voucher_codes={locationData.voucher_codes}
    />
  );
};

export default withRouter(withStyles(styles)(OrgViewLocation));
