import { query, types, params } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../react-app-env';

export interface Device {
  id: string;
  name: string;
  device_type: string;
}

export interface VoucherCode {
  id: string;
  code: string;
}

export interface Location {
  id: string;
  name: string;
  email: string;
  phone: string;
  website: string;
  tax_number: string;
  currency: string;
  locale: string;
  number_of_tables: number;
  location_type: string;
  address_line_1: string;
  address_line_2: string;
  address_city: string;
  address_country: string;
  address_postcode: string;
  can_manage_menus: boolean;
  has_kitchen_printer: boolean;
  has_bar_printer: boolean;
  has_staff: boolean;
  has_station_printers: boolean;
  service_charge: any | null;
  idle_timeout: number;
  location_menu_id: string | undefined;
  ordering_system_primary_color: string | undefined;
  ordering_system_secondary_color: string | undefined;
  header_image_added: boolean;
  logo_image_added: boolean;
  ordering_system_open_hours: any | null;
  ordering_system_preorder_hours: any | null;
  preorder_days_limit: number;
  preorder_pacing_limit: number;
  order_interval: number;
  inventory_restock_type: string;
  organisation_id: string;
  devices: Device[];
  voucher_codes: VoucherCode[];
}

export const _homeInitQuery = () => ({
  locations: [
    {
      id: types.string,
      name: types.string,
      email: types.string,
      phone: types.string,
      website: types.string,
      tax_number: types.string,
      currency: types.string,
      locale: types.string,
      number_of_tables: types.number,
      location_type: types.string,
      address_line_1: types.string,
      address_line_2: types.string,
      address_city: types.string,
      address_country: types.string,
      address_postcode: types.string,
      can_manage_menus: types.boolean,
      has_kitchen_printer: types.boolean,
      has_bar_printer: types.boolean,
      has_staff: types.boolean,
      has_station_printers: types.boolean,
      service_charge: types.custom<any | null>(),
      idle_timeout: types.number,
      location_menu_id: types.string,
      ordering_system_primary_color: types.string,
      ordering_system_secondary_color: types.string,
      header_image_added: types.boolean,
      logo_image_added: types.boolean,
      ordering_system_open_hours: types.custom<any | null>(),
      ordering_system_preorder_hours: types.custom<any | null>(),
      preorder_days_limit: types.number,
      preorder_pacing_limit: types.number,
      order_interval: types.number,
      inventory_restock_type: types.string,
      organisation_id: types.string,
      devices: [
        {
          id: types.string,
          name: types.string,
          device_type: types.string,
        },
      ],
      voucher_codes: [
        {
          id: types.string,
          code: types.string,
        },
      ],
    },
  ],
  location_integrations: [
    {
      vouchers: types.boolean,
    },
  ],
});

export const homeInitQuery = () => gql`
  ${query(_homeInitQuery())}
`;
