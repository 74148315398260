export interface PriceItem {
  price: number;
  quantity: number;
  modifiers?: number[];
}

export interface MenuData {
  categories: Array<{
    category_type: 'DISH' | 'DRINK';
    dish_assignments?: Array<{ dish: any }>;
    drink_assignments?: Array<{ drink: any }>;
  }>;
}

const calculateItemTotalPrice = (item: any): number => {
  const modifiersPrice = item.modifiers?.reduce((sum: number, mod: { price: number }) => sum + mod.price, 0) || 0;
  return item.price + modifiersPrice;
};

export function calculateTotalPrice(
  cart: { [key: string]: number },
  menuData?: MenuData
): number {
  if (!menuData || !cart) return 0;

  return Object.entries(cart).reduce((total, [cartKey, quantity]) => {
    const [productId, modifiersKey] = cartKey.split(':');
    const modifiersArray = modifiersKey ? modifiersKey.split(',') : [];
    
    // Find the product in menu data
    const product = menuData.categories
      .flatMap((category) =>
        category.category_type === 'DISH'
          ? category.dish_assignments?.map((item) => item.dish) || []
          : category.drink_assignments?.map((item) => item.drink) || []
      )
      .find((item) => item?.id === productId);

    if (!product) return total;

    // Get modifiers for this product
    const modifiers = product.modifier_assignments
      ?.filter((mod: any) => modifiersArray.includes(mod.modifier.name))
      .map((mod: any) => ({
        price: mod.modifier.price,
      }));

    // Calculate total price for this item including modifiers
    const itemTotal = calculateItemTotalPrice({ 
      price: product.price,
      modifiers
    }) * quantity;

    return total + itemTotal;
  }, 0) / 100
}
