import React, { useState, useEffect } from 'react';
import { useGetThreeDSecureSettings, useUpdateThreeDSecureSettings } from './queries';
import {
  makeStyles,
  Theme,
  createStyles,
  Typography,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';

interface ThreeDSecureSettingsProps {
  locationId: string;
}

interface AdvancedSettings {
  scenario: string;
  action: 'ACCEPT' | 'DECLINE';
}

const defaultAdvancedSettings: AdvancedSettings[] = [
  {
    scenario: 'AUTHENTICATION_SUCCESSFUL',
    action: 'ACCEPT', // PayPal's recommended setting
  },
  {
    scenario: 'ATTEMPTED_AUTHENTICATION',
    action: 'ACCEPT', // PayPal's recommended setting
  },
  {
    scenario: 'AUTHENTICATION_COULD_NOT_BE_PERFORMED',
    action: 'ACCEPT', // PayPal's recommended setting
  },
  {
    scenario: 'AUTHENTICATION_FAILED',
    action: 'DECLINE', // PayPal's recommended setting
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    sectionTitle: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    select: {
      minWidth: 200,
    },
    table: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    resetButton: {
      marginTop: theme.spacing(2),
    },
    saveButton: {
      marginTop: theme.spacing(2),
    },
  })
);

const ThreeDSecureSettings: React.FC<ThreeDSecureSettingsProps> = ({ locationId }) => {
  const classes = useStyles();

  const [is3DEnabled, setIs3DEnabled] = useState<boolean>(true);
  const [preference, setPreference] = useState<string>('ANY');
  const [advancedSettings, setAdvancedSettings] = useState<AdvancedSettings[]>(defaultAdvancedSettings);

  const { data, isLoading, error } = useGetThreeDSecureSettings(locationId);

  const { mutate: updateSettings, isLoading: isUpdating } = useUpdateThreeDSecureSettings(locationId);

  useEffect(() => {
    if (data) {
      setIs3DEnabled(data.is3DEnabled ?? true);
      setPreference(data.preference ?? 'ANY');
      setAdvancedSettings(data.advancedSettings ?? defaultAdvancedSettings);
    }
  }, [data]);

  const handleSave = () => {
    const settingsToSave = {
      is3DEnabled,
      preference,
      advancedSettings,
    };
    updateSettings(settingsToSave);
  };

  const resetToDefault = () => {
    setIs3DEnabled(true);
    setPreference('ANY');
    setAdvancedSettings(defaultAdvancedSettings);
  };

  const handleAdvancedSettingChange = (index: number, action: 'ACCEPT' | 'DECLINE') => {
    const newSettings = [...advancedSettings];
    newSettings[index].action = action;
    setAdvancedSettings(newSettings);
  };

  return (
    <div className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        3D Secure Settings
      </Typography>
      {isLoading && <CircularProgress />}
      {error && <Typography color="error">Error loading settings: {(error as Error).message}</Typography>}
      {!isLoading && !error && (
        <div>
          <Typography variant="body1">
            <strong>What is 3D Secure?</strong>
            <br />
            3D Secure enables you to authenticate cardholders through card issuers. It reduces the likelihood of fraud when you use supported cards and improves transaction
            performance. A successful 3D Secure authentication can shift liability for chargebacks due to fraud from you to the card issuer.
          </Typography>

          <FormControlLabel
            control={<Checkbox checked={is3DEnabled} onChange={(e) => setIs3DEnabled(e.target.checked)} name="enable3dsecure" color="primary" />}
            label="Enable 3D Secure"
          />

          {is3DEnabled && (
            <div>
              <Typography variant="h5" className={classes.sectionTitle}>
                3D Secure Challenge Preference
              </Typography>
              <Typography variant="body2">Select your preference for when to challenge customers during the authentication process.</Typography>

              <Select id="preference" value={preference} onChange={(e) => setPreference(e.target.value as string)} className={classes.select}>
                <MenuItem value="NO_PREFERENCE">No Preference</MenuItem>
                <MenuItem value="NO_CHALLENGE_PREFERRED">No Challenge Preferred</MenuItem>
                <MenuItem value="CHALLENGE_PREFERRED">Challenge Preferred</MenuItem>
                <MenuItem value="CHALLENGE_AS_MANDATE">Challenge as Mandate</MenuItem>
              </Select>

              <Typography variant="h5" className={classes.sectionTitle}>
                Advanced Settings
              </Typography>
              <Typography variant="body2">
                3D Secure authentication is performed only if the card is enrolled for the service. In scenarios where the 3D Secure authentication hasn't been successful, you have
                the option to complete the payment at your own risk, meaning that you, as the seller, will be liable in case of a chargeback.
              </Typography>

              <TableContainer component={Paper} className={classes.table}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Scenario</TableCell>
                      <TableCell>Action</TableCell>
                      <TableCell>PayPal Recommended</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {advancedSettings.map((setting, index) => (
                      <TableRow key={setting.scenario}>
                        <TableCell>{setting.scenario}</TableCell>
                        <TableCell>
                          <Select value={setting.action} onChange={(e) => handleAdvancedSettingChange(index, e.target.value as 'ACCEPT' | 'DECLINE')}>
                            <MenuItem value="ACCEPT">Accept</MenuItem>
                            <MenuItem value="DECLINE">Decline</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell>{defaultAdvancedSettings[index].action}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button variant="outlined" color="primary" onClick={resetToDefault} className={classes.resetButton}>
                Reset to Recommended Settings
              </Button>
            </div>
          )}
          <Button variant="contained" color="primary" onClick={handleSave} disabled={isUpdating} className={classes.saveButton}>
            {isUpdating ? 'Saving...' : 'Save Settings'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ThreeDSecureSettings;
