import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import EditLocation from '../../../../components/Global/Location/Edit';

import { editLocationInitQuery, Location, LocationPrinter, LocationVoucher, voucherCodesInitQuery } from './queries';

import styles from './styles';
import { Breadcrum } from '../../../../layouts/Dashboard/components/Topbar';
import { selectLocationUsersInitQuery } from '../Printers/List/queries';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  location_id: string;
}

const OrgEditLocation = ({ classes, history }: Props): React.ReactElement => {
  const { location_id } = useParams<UrlParams>();

  const [location, setLocation] = useState<Location>({ name: '' });

  const { data: editLocationInitData } = useQuery(editLocationInitQuery(location_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editLocationInitData) {
      setLocation(editLocationInitData.locations_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [editLocationInitData]);

  const handleFinish = () => {
    history.push(`/org/locations/${location_id}`);
  };
  const [breadcrumbsUpdated, setBreadcrumbsUpdated] = useState(false);

  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrum[]>([
    { label: 'Locations', link: `/org/locations` },
    { label: location.name, link: `/org/locations/${location_id}/edit` },
  ]);
  useEffect(() => {
    if (location.name && location_id) {
      setBreadcrumbs([
        { label: 'Locations', link: `/org/locations` },
        { label: location.name, link: `/org/locations/${location_id}/edit` },
      ]);
      setBreadcrumbsUpdated(true);
    }
  }, [location, location_id]);

  const handleCreateUser = () => {
    history.push(`/org/locations/${location_id}/users/create`);
  };

  const handleViewUser = (id: string) => {
    history.push(`/org/locations/${location_id}/users/${id}`);
  };

  const handleEditUser = (id: string) => {
    history.push(`/org/locations/${location_id}/users/${id}/edit`);
  };

  const [locationPrinter, setLocationPrinter] = useState<LocationPrinter>({
    name: '',
    devices: [],
  });

  const { data: locationUsersInitQuery } = useQuery(selectLocationUsersInitQuery(location_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && locationUsersInitQuery) {
      setLocationPrinter(locationUsersInitQuery.locations_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [locationUsersInitQuery]);

  const handleCreatePrinter = () => {
    history.push(`/locations/${location_id}/printers/create`);
  };

  const handleEditPrinter = (id: string) => {
    history.push(`/locations/${location_id}/printers/${id}/edit`);
  };

  const handleCreateStaff = () => {
    history.push(`/org/locations/${location_id}/staff/create`);
  };

  const handleViewStaff = (id: string) => {
    history.push(`/org/locations/${location_id}/staff/${id}`);
  };

  const handleEditStaff = (id: string) => {
    history.push(`/org/locations/${location_id}/staff/${id}/edit`);
  };

  const [locationVoucher, setLocationVoucher] = useState<LocationVoucher>({
    name: '',
    currency: '',
    locale: '',
    voucher_codes: [],
  });

  const { data: voucherCodesInitData } = useQuery(voucherCodesInitQuery(location_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && voucherCodesInitData) {
      setLocationVoucher(voucherCodesInitData.locations_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [voucherCodesInitData]);

  const handleCreateVoucher = () => {
    history.push(`/org/locations/${location_id}/vouchers/create`);
  };

  const handleEditVoucher = (id: string) => {
    history.push(`/org/locations/${location_id}/vouchers/${id}/edit`);
  };
  return (
    <>
      <>
        <EditLocation
          location_id={location_id}
          organisation_id={undefined}
          handleFinish={handleFinish}
          breadcrumbs={breadcrumbs}
          setBreadcrumbs={setBreadcrumbs}
          breadcrumbsUpdated={breadcrumbsUpdated}
          handleCreateUser={handleCreateUser}
          handleViewUser={handleViewUser}
          handleEditUser={handleEditUser}
          printers={locationPrinter.devices}
          handleCreatePrinter={handleCreatePrinter}
          handleEditPrinter={handleEditPrinter}
          handleCreateStaff={handleCreateStaff}
          handleViewStaff={handleViewStaff}
          handleEditStaff={handleEditStaff}
          handleCreateVoucher={handleCreateVoucher}
          handleEditVoucher={handleEditVoucher}
          currency={locationVoucher.currency}
          locale={locationVoucher.locale}
          voucher_codes={locationVoucher.voucher_codes}
        />
      </>
    </>
  );
};

export default withRouter(withStyles(styles)(OrgEditLocation));
