import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import Dashboard from '../../../../layouts/Dashboard';
import ListLocations, { Location } from '../../../../components/Global/Location/List';

import { locationsInitQuery } from './queries';

import styles from './styles';
interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const OrgListLocations = ({ classes, history }: Props): React.ReactElement => {
  const [locations, setLocations] = useState<Location[]>([]);

  const { data: locationsInitData } = useQuery(locationsInitQuery(), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && locationsInitData) {
      setLocations(locationsInitData.locations);
    }
    return () => {
      mounted = false;
    };
  }, [locationsInitData]);

  const handleCreate = () => {
    history.push(`/org/locations/create`);
  };

  const handleEdit = (id: string) => {
    history.push(`/org/locations/${id}/edit`);
  };

  const handleView = (id: string) => {
    // history.push(`/org/locations/${id}`);
    history.push(`/org/locations/${id}/edit`);
  };

  console.log(locations, 'locations');

  return (
    <Dashboard breadcrumbs={[{ label: 'Locations' }]}>
      <ListLocations locations={locations} handleCreate={handleCreate} handleEdit={handleEdit} handleView={handleView} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(OrgListLocations));
