import React, { useEffect } from 'react';

type LoadGooglePaySDKCallback = () => Promise<void>;

const loadGooglePaySDK = (onGooglePayLoadedCallback: LoadGooglePaySDKCallback): void => {
  const script = document.createElement('script');
  script.src = 'https://pay.google.com/gp/p/js/pay.js';
  script.async = true;
  script.onload = () => {
    //@ts-ignore
    if (window.google && window.paypal && window.paypal.Googlepay) {
      onGooglePayLoadedCallback().catch(console.error);
    }
  };
  document.body.appendChild(script);
};

interface PaymentData {
  paymentMethodData: any;
}

interface GooglePayConfig {
  allowedPaymentMethods: any;
  merchantInfo: any;
  apiVersion: number;
  apiVersionMinor: number;
  countryCode: string;
}

interface GooglePayButtonProps {
  createOrderFunc: (source: string) => Promise<string | null>;
  onApproveFunc: (data: any, actions: any) => Promise<void>;
  cartItems: any;
  totalPrice: number;
  tax: number;
  currency: string;
}

const GooglePayButton: React.FC<GooglePayButtonProps> = ({ createOrderFunc, onApproveFunc, cartItems, totalPrice, tax, currency }) => {
  useEffect(() => {
    loadGooglePaySDK(onGooglePayLoaded);
  }, []);

  const getGooglePaymentsClient = (): google.payments.api.PaymentsClient => {
    //@ts-ignore
    if (!window.paymentsClient) {
      //@ts-ignore
      window.paymentsClient = new window.google.payments.api.PaymentsClient({
        environment: process.env.REACT_APP_PAYPAL_GP_ENVIRONMENT ?? 'TEST', // or "PRODUCTION"
        paymentDataCallbacks: {
          onPaymentAuthorized: onPaymentAuthorized,
        },
      });
    }
    //@ts-ignore
    return window.paymentsClient;
  };

  const onPaymentAuthorized = async (paymentData: PaymentData): Promise<{ transactionState: string }> => {
    try {
      await processPayment(paymentData);
      return { transactionState: 'SUCCESS' };
    } catch (error) {
      return { transactionState: 'ERROR' };
    }
  };

  const onGooglePaymentButtonClicked = async (): Promise<void> => {
    try {
      const paymentDataRequest = await getGooglePaymentDataRequest();
      const paymentsClient = getGooglePaymentsClient();
      paymentsClient.loadPaymentData(paymentDataRequest);
    } catch (error) {
      console.error(error);
    }
  };

  const addGooglePayButton = (): void => {
    const paymentsClient = getGooglePaymentsClient();
    const button = paymentsClient.createButton({
      onClick: onGooglePaymentButtonClicked,
    });
    document.getElementById('googlepay-container')?.appendChild(button);
  };

  const onGooglePayLoaded = async (): Promise<void> => {
    try {
      const paymentsClient = getGooglePaymentsClient();
      const googlepayConfig = await getGooglePayConfig();
      const { allowedPaymentMethods, apiVersion, apiVersionMinor } = googlepayConfig;

      const response = await paymentsClient.isReadyToPay({
        allowedPaymentMethods,
        apiVersion,
        apiVersionMinor,
      });

      if (response.result) {
        addGooglePayButton();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getGooglePayConfig = async (): Promise<GooglePayConfig> => {
    //@ts-ignore
    if (!window.googlepayConfig) {
      //@ts-ignore
      window.googlepayConfig = await window.paypal.Googlepay().config();
    }
    //@ts-ignore
    return window.googlepayConfig;
  };

  const getGooglePaymentDataRequest = async (): Promise<any> => {
    const { allowedPaymentMethods, merchantInfo, apiVersion, apiVersionMinor, countryCode } = await getGooglePayConfig();
    return {
      apiVersion,
      apiVersionMinor,
      allowedPaymentMethods,
      merchantInfo,
      transactionInfo: getGoogleTransactionInfo(countryCode),
      callbackIntents: ['PAYMENT_AUTHORIZATION'],
    };
  };

  const getGoogleTransactionInfo = (countryCode: string): any => {
    return {
      displayItems: [
        { label: 'Subtotal', type: 'SUBTOTAL', price: (totalPrice / 100).toFixed(2) },
        { label: 'Tax', type: 'TAX', price: (tax / 100).toFixed(2) },
      ],
      countryCode,
      currencyCode: currency,
      totalPriceStatus: 'FINAL',
      totalPrice: (totalPrice / 100).toFixed(2),
      totalPriceLabel: 'Total',
    };
  };

  const processPayment = async (paymentData: PaymentData): Promise<void> => {
    const orderId = await createOrderFunc('google_pay');
    if (!orderId) {
      throw new Error('Order creation failed');
    }

    //@ts-ignore
    const { status } = await window.paypal.Googlepay().confirmOrder({
      orderId,
      paymentMethodData: paymentData.paymentMethodData,
    });

    if (status === 'PAYER_ACTION_REQUIRED') {
      //@ts-ignore
      await window.paypal.Googlepay().initiatePayerAction({ orderId });
    }

    await onApproveFunc({ orderID: orderId }, {});
  };

  return <div id="googlepay-container"></div>;
};

export default GooglePayButton;
