import React, { useEffect, useState } from 'react';

interface ApplePayButtonProps {
  createOrderFunc: (source: string) => Promise<string | null>;
  onApproveFunc: (data: any, actions: any) => Promise<void>;
  cartItems: any;
  totalPrice: number;
  tax: number;
  currency: string;
}

const ApplePayButton: React.FC<ApplePayButtonProps> = ({ createOrderFunc, onApproveFunc, cartItems, totalPrice, tax, currency }) => {
  const [isApplePayEligible, setIsApplePayEligible] = useState(false);
  const [applePayConfig, setApplePayConfig] = useState<any>(null);

  useEffect(() => {
    (async () => {
      //@ts-ignore
      if (!window.paypal?.Applepay) {
        console.log('PayPal Apple Pay component not found.');
        return;
      }

      try {
        // 2) Get PayPal Apple Pay config
        //@ts-ignore
        const applepay = window.paypal.Applepay();
        const config = await applepay.config();

        if (!config.isEligible) {
          console.log('Apple Pay is not eligible on this device/browser.');
          return;
        }

        setIsApplePayEligible(true);
        setApplePayConfig(config);
        console.log(config, 'config');
        // 3) Dynamically insert the <apple-pay-button>
        const container = document.getElementById('applepay-container');
        if (container) {
          container.innerHTML = '<apple-pay-button id="btn-appl" buttonstyle="black" type="buy" locale="en"></apple-pay-button>';
        }

        // 4) Attach the click event to the injected element
        const button = document.getElementById('btn-appl');
        button?.addEventListener('click', () => handleClick(config));
      } catch (error) {
        console.error('Error configuring Apple Pay via PayPal:', error);
      }
    })();
    // Cleanup: remove the injected button on unmount
    return () => {
      const btn = document.getElementById('btn-appl');
      if (btn) {
        btn.removeEventListener('click', handleClick);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * The click handler that starts the ApplePaySession flow.
   */
  const handleClick = async (applePayConfig: any) => {
    console.log('Apple Pay button clicked here before', applePayConfig);
    if (!applePayConfig) {
      console.error('ApplePay config not available.');
      return;
    }

    const { countryCode, currencyCode, merchantCapabilities, supportedNetworks } = applePayConfig;

    // 1) Create the Apple Pay session request object
    const paymentRequest = {
      countryCode,
      currencyCode: currencyCode || currency, // or override if you want a specific currency code
      merchantCapabilities,
      supportedNetworks,
      requiredBillingContactFields: ['name', 'phone', 'email', 'postalAddress'],
      requiredShippingContactFields: [],
      total: {
        label: 'Order Total',
        amount: (totalPrice / 100).toFixed(2), // convert cents to dollars if needed
        type: 'final',
      },
    };

    // 2) Initialize ApplePaySession
    //@ts-ignore
    const session = new window.ApplePaySession(4, paymentRequest);

    // 3) onvalidatemerchant
    session.onvalidatemerchant = async (event: any) => {
      try {
        //@ts-ignore
        const applepay = window.paypal.Applepay();
        const payload = await applepay.validateMerchant({ validationUrl: event.validationURL });
        session.completeMerchantValidation(payload.merchantSession);
      } catch (err) {
        console.error('Merchant validation failed:', err);
        session.abort();
      }
    };

    // 4) onpaymentmethodselected
    session.onpaymentmethodselected = () => {
      // You can adjust the total or line items here if needed
      session.completePaymentMethodSelection({
        newTotal: paymentRequest.total,
      });
    };

    // 5) onpaymentauthorized
    session.onpaymentauthorized = async (event: any) => {
      try {
        // A) Create your PayPal order
        const orderId = await createOrderFunc('apple_pay');
        if (!orderId) {
          throw new Error('Order creation returned null');
        }

        // B) Confirm the order with the Apple Pay token via PayPal
        //@ts-ignore
        const applepay = window.paypal.Applepay();
        await applepay.confirmOrder({
          orderId,
          token: event.payment.token,
          billingContact: event.payment.billingContact,
          shippingContact: event.payment.shippingContact,
        });

        // C) (Optional) Final approval step in your code
        await onApproveFunc({ orderID: orderId }, {});

        // D) Complete the session
        //@ts-ignore
        session.completePayment({ status: window.ApplePaySession.STATUS_SUCCESS });
      } catch (err) {
        console.error('Apple Pay payment failed:', err);
        //@ts-ignore
        session.completePayment({ status: window.ApplePaySession.STATUS_FAILURE });
      }
    };

    // 6) oncancel
    session.oncancel = () => {
      console.log('Apple Pay session was cancelled by the user.');
    };

    // 7) Start the session
    session.begin();
  };

  // If we know Apple Pay is not eligible, or config not fetched yet, optionally return null
  if (!isApplePayEligible) {
    return null;
  }

  // We just render an empty container, since we're injecting <apple-pay-button> manually
  return <div id="applepay-container" />;
};

export default ApplePayButton;
